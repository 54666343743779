import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import { Typography, Container, Button, Box, Popover, IconButton } from '@mui/material';
import axios from 'axios';
import { ThemeContext } from '../../mainComponents/theme.js';
// import { Navigate } from "react-router-dom";
import { API_BASE_URL, API_ENDPOINTS } from '../../mainComponents/api.js';
import TextToSpeechComponent from './components/texttospeach.js';
import ScrollProgressIndicator from './components/ReadingProgress.js';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
// import BlurredLoginOverlay from '../components/BulrrLoginOverlay.js';
import YouTube from 'react-youtube';
import FullScreenLoading from '../../components/loadingScreen.js';
import BackButton from '../../components/backbutton.js';
import PageWrapper from "../../components/customWidth.js";
import { useMediaQuery } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import ChatBot from './components/chatBot.js';
import FullBookButton from './components/fullBookButton.js';

const fetchBook = async (bookId) => {
  const response = await fetch(`${API_BASE_URL}${API_ENDPOINTS.BOOK}/${bookId}`, {
    timeout: 10000, // 10 seconds timeout
  });
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  return response.json();
};


export default function FullPost() {
  const [isDelayedLoading, setIsDelayedLoading] = React.useState(true);
  // const location = useLocation();
  const navigate = useNavigate();
  // const { post } = location.state || {};
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedWord, setSelectedWord] = useState('');
  const [wordMeaning, setWordMeaning] = useState('');
  const [isLiked, setIsLiked] = useState(false);
  const { isDarkMode } = React.useContext(ThemeContext);
  const { bookId } = useParams();
  const { data: post, isLoading, error } = useQuery(['book', bookId], () => fetchBook(bookId));
  const isWideScreen = useMediaQuery('(min-width:1080px)');
  const customWidth = isWideScreen ? "60%" : "100%";
  // const [username, setUsername] = useState(localStorage.getItem('username'));
  const bookContentRef = useRef(null);

  React.useEffect(() => {
    if (!isLoading) {
      const delay = setTimeout(() => {
        setIsDelayedLoading(false);
      }, 500); // 
      return () => clearTimeout(delay);
    }
  }, [isLoading]);

  React.useEffect(() => {
    if (!isLoading) {
      const delay = setTimeout(() => {
        setIsDelayedLoading(false);
      }, 500);
      return () => clearTimeout(delay);
    }
  }, [isLoading]);

  useEffect(() => {
    const fetchBookmarkAndScroll = async () => {
      const username = localStorage.getItem('username');
      if (!username || !bookId) return; // Use bookId here

      try {
        const response = await fetch(`${API_BASE_URL}${API_ENDPOINTS.BOOKMARK}/${bookId}?username=${username}`); // Use bookId here
        if (response.ok) {
          const data = await response.json();
          if (data.progress !== null) {
            // Ensure scrollHeight and clientHeight are valid numbers and scrollHeight is greater than clientHeight
            if (document.documentElement.scrollHeight > document.documentElement.clientHeight && typeof data.progress === 'number') {
              const scrollTarget = data.progress * (document.documentElement.scrollHeight - document.documentElement.clientHeight);
              window.scrollTo(0, scrollTarget);
            } else {
              console.warn('Document not scrollable or invalid bookmark progress.');
            }
          }
        } else {
          console.error('Error fetching bookmark for scroll. Response status:', response.status);
        }
      } catch (error) {
        console.error('Error fetching bookmark for scroll:', error);
      }
    };

    // Delay the execution of fetchBookmarkAndScroll to allow the component to fully render
    const timeoutId = setTimeout(() => {
      fetchBookmarkAndScroll();
    }, 500);

    return () => clearTimeout(timeoutId); // Clear timeout if component unmounts
  }, [bookId]); // Dependency should be bookId


  if (isDelayedLoading || isLoading) {
    return <FullScreenLoading />;
  }

  if (error) return <div>Error: {error.message}</div>;


  // if (!localStorage.getItem('username')) {
  //   return <Navigate to="/login" />;
  // }
  // const isLoggedIn = !!localStorage.getItem('username');
  const handleLikeClick = async () => {
    const username = localStorage.getItem('username');
    if (!username) {
      console.log('User not logged in');
      alert('Please log in to like this post');
      return;
    }

    // console.log(post.title);
    if (post && post.title) {
      try {
        const response = await axios.post(`${API_BASE_URL}${API_ENDPOINTS.ENTRY}`, {
          username: username,
          postId: post.title
        });
        console.log('Read list updated:', response.data);
        setIsLiked(true);
        alert(response.data.message);
        window.location.reload();
      } catch (error) {
        console.error('Error updating read list:', error);
        alert('Error adding post to read list');
      }
    }
  };
  const handleFullBookClick = () => {
    // Encode the file path to handle special characters
    const epubUrl = post.bookFile;
    
    navigate('/YourBook',  { state: { epubUrl } });
  };
  const backButtonStyle = {
    marginRight: '10px', // Space between button and input
  };

  const handleWordClick = async (event) => {
    // Remove punctuation and trim whitespace
    const word = event.target.textContent.trim().replace(/[^\w\s]|_/g, "").toLowerCase();
    setSelectedWord(word);
    setAnchorEl(event.currentTarget);

    if (word.length === 0) {
      setWordMeaning('Please select a valid word');
      return;
    }

    try {
      const response = await fetch(`https://api.dictionaryapi.dev/api/v2/entries/en/${word}`);
      if (!response.ok) {
        throw new Error('Word not found');
      }
      const data = await response.json();
      if (data && data[0] && data[0].meanings && data[0].meanings[0] && data[0].meanings[0].definitions) {
        setWordMeaning(data[0].meanings[0].definitions[0].definition);
      } else {
        setWordMeaning('Definition not found');
      }
    } catch (error) {
      console.error('Error fetching word definition:', error);
      setWordMeaning(error.message === 'Word not found' ? 'Word not found in dictionary' : 'Error fetching definition');
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleBack = () => {
    navigate(`/library`);
  }
  const open = Boolean(anchorEl);

  const wrapWordsInSpans = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');

    const wrapTextNode = (node) => {
      if (node.nodeType === Node.TEXT_NODE && node.textContent.trim() !== '') {
        const span = doc.createElement('span');
        span.innerHTML = node.textContent.replace(/(\S+)/g, '<span class="clickable-word">$1</span>');
        node.parentNode.replaceChild(span, node);
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        Array.from(node.childNodes).forEach(wrapTextNode);
      }
    };

    wrapTextNode(doc.body);
    return doc.body.innerHTML;
  };

  const wrappedContent = wrapWordsInSpans(post.content);

  return (
    <PageWrapper customWidth={customWidth} >
      <Helmet>
        <title>{post?.title || "Summary"}</title>
        <meta name="description" content={`Read the full summary of ${post?.title || 'a book'}`} />
        <meta name="keywords" content={`Readquest, read quest, book summaries, Sanket Magodia, read books, books, library, quotes, collection, ${post?.title}, ${post?.genre}`} />
        {/* Add other meta tags as needed */}
      </Helmet>
      <Container maxWidth="False">

        {/* {!isLoggedIn && <BlurredLoginOverlay />} */}
        <Box sx={{ my: 4 }} >
          <BackButton handleBack={handleBack} style={backButtonStyle} /><br /><br />
          <Typography variant="h2" component="h2" gutterBottom>
            {post.title}
          </Typography>

          <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
            <Typography variant="subtitle1" gutterBottom color="orange" style={{ textAlign: 'right' }}>
              By {post.author}
            </Typography>
          </div>
          <Box sx={{ my: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            {/* Image */}
            {!post.bookFile &&<img
              src={`data:image/jpeg;base64,${post.image_data}`}
              alt={post.title}
              style={{
                borderRadius: '16px',
                width: '150px',    // Set image to take up 30% of the container's width
                height: 'auto',  // Maintain aspect ratio
                objectFit: 'cover', // Ensure the image is cropped and fits within the box
              }}
            />}
            {post.bookFile && (<FullBookButton post={post} />)}
            {/* YouTube video */}
            {post.video && (
              <Box
                sx={{
                  borderRadius: '16px',
                  position: 'relative',
                  width: '65%',   // Set YouTube video to take up 65% of the container's width
                  height: '100%', // Let height adjust with aspect ratio
                }}
              >
                <YouTube
                  videoId={post.video}
                  opts={{
                    borderRadius: '16px',
                    height: '100%',   // Set height to 100% of the container's height
                    width: '100%',    // Set width to 100% of the container's width
                    playerVars: {
                      autoplay: 0,    // Auto-play video if needed
                    },
                  }}
                />
              </Box>
            )}
          </Box>
          <TextToSpeechComponent text={post.content.replace(/<[^>]*>?/gm, '')} isDarkMode={true} />
          <ScrollProgressIndicator bookId={bookId} bookContentRef={bookContentRef} />
          <Typography
            className='post'
            variant="body1"
            component="div"
            dangerouslySetInnerHTML={{ __html: wrappedContent }}
            onClick={(e) => {
              if (e.target.classList.contains('clickable-word')) {
                handleWordClick(e);
              }
            }}
            sx={{
              fontFamily: '"Literata", serif',
              fontSize: '1.2rem',
              '& .clickable-word': {
                cursor: 'pointer',
                '&:hover': {
                  textDecoration: 'underline',

                },
              },
            }}
          />
          <Button onClick={() => navigate(-1)}>Back to library</Button>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              textAlign: 'center'
            }}
          >
            <IconButton
              onClick={handleLikeClick}
              sx={{
                color: isDarkMode ? 'white' : 'black',
                width: '40px',  // Adjust size as needed
                height: '40px', // Adjust size as needed
                borderRadius: '50%',
                padding: 0,
                '&:hover': {
                  backgroundColor: 'transparent',
                },
                '&.Mui-disabled': {
                  color: isDarkMode ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0, 0, 0, 0.26)',
                },
              }}

            >
              <ThumbUpIcon className='thumb' sx={{ fontSize: '24px' }} /> {/* Adjust icon size as needed */}
            </IconButton>
            <Typography
              variant="caption"
              sx={{
                mt: 1,
                color: isDarkMode ? 'white' : 'black',
              }}
            >
              Click here to complete your quest
            </Typography>
          </Box>
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Box sx={{ p: 2, maxWidth: 300 }}>
              <Typography variant="h6">{selectedWord}</Typography>
              <Typography variant="body2">{wordMeaning}</Typography>
            </Box>
          </Popover>
        </Box>
      </Container>
      <ChatBot bookContent={post.content} bookTitle = {post.title} />
    </PageWrapper>
  );
}
