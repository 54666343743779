import React, { useState, useEffect, useRef, useContext } from 'react';
import ePub from 'epubjs';
import { ThemeContext } from '../../../mainComponents/theme.js';
import {
  AiOutlineLeft,
  AiOutlineRight,
  AiOutlineZoomIn,
  AiOutlineZoomOut,
  AiOutlineDownload,
} from 'react-icons/ai';
import { Container, Box, IconButton, Typography, CircularProgress, LinearProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import BackButton from '../../../components/backbutton.js';
import { useNavigate } from 'react-router-dom';
import Switch from '@mui/material/Switch';
import FullScreenLoading from '../../../components/loadingScreen.js';
const KindleReader = ({ epubUrl }) => {  
  const { isDarkMode, toggleTheme } = useContext(ThemeContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [readingProgress, setReadingProgress] = useState(0);
  const [fontSize, setFontSize] = useState(100);
  const [isLoading, setIsLoading] = useState(true);
  const [bookTitle, setBookTitle] = useState('');
  const [currentCfi, setCurrentCfi] = useState('');
  const [locationsGenerated, setLocationsGenerated] = useState(false);
  const renditionRef = useRef(null);
  const bookRef = useRef(null);
  const readerContainerRef = useRef(null);
  const navigate = useNavigate();

  // Generate a unique storage key based on the book URL
  const getStorageKey = (url) => {
    return `epub-reader-${url.split('/').pop().replace(/\W/g, '')}`;
  };

  // Load saved reading state from localStorage
  const loadSavedState = () => {
    try {
      const storageKey = getStorageKey(epubUrl);
      const savedState = localStorage.getItem(storageKey);
      
      if (savedState) {
        const { cfi, fontSize: savedFontSize } = JSON.parse(savedState);
        if (savedFontSize) setFontSize(savedFontSize);
        return cfi;
      }
    } catch (error) {
      console.error('Error loading saved reading state:', error);
    }
    return null;
  };

  // Save current reading state to localStorage
  const saveReadingState = (cfi) => {
    try {
      const storageKey = getStorageKey(epubUrl);
      localStorage.setItem(storageKey, JSON.stringify({
        cfi,
        fontSize,
        lastRead: new Date().toISOString()
      }));
    } catch (error) {
      console.error('Error saving reading state:', error);
    }
  };

  useEffect(() => {
    const book = ePub(epubUrl);
    bookRef.current = book;

    book.loaded.metadata.then((metadata) => {
      setBookTitle(metadata.title);
    });

    // Generate locations for page calculation
    book.ready.then(() => {
      if (!book.locations.length()) {
        book.locations.generate(1024).then(() => {
          setLocationsGenerated(true);
          localStorage.setItem(`${getStorageKey(epubUrl)}-locations`, book.locations.save());
        });
      } else {
        setLocationsGenerated(true);
      }
      
      // Try to load saved locations
      try {
        const savedLocations = localStorage.getItem(`${getStorageKey(epubUrl)}-locations`);
        if (savedLocations) {
          book.locations.load(savedLocations);
          setLocationsGenerated(true);
        }
      } catch (e) {
        console.error("Error loading saved locations", e);
      }
    });

    const rendition = book.renderTo('viewer', {
      width: '100%',
      spread: 'none',
      flow: 'paginated',
    });
    renditionRef.current = rendition;

    rendition.themes.fontSize(`${fontSize}%`);
    applyTheme(isDarkMode ? 'dark' : 'light', rendition);

    // Check for saved position
    const savedCfi = loadSavedState();

    const displayPromise = savedCfi 
      ? rendition.display(savedCfi)
      : rendition.display();

    displayPromise.then(() => {
      setIsLoading(false);
      adjustReaderHeight();
    });

    rendition.on('relocated', (location) => {
      // Update current CFI
      if (location && location.start) {
        const cfi = location.start.cfi;
        setCurrentCfi(cfi);
        saveReadingState(cfi);
        
        // Update progress
        if (book.locations.length()) {
          const progress = book.locations.percentageFromCfi(cfi);
          setReadingProgress(progress * 100);
          
          // Calculate current page
          const totalPagesCount = book.locations.length();
          const currentPageNum = Math.ceil(progress * totalPagesCount);
          setCurrentPage(currentPageNum || 1);
          setTotalPages(totalPagesCount);
        } else {
          // Fallback if locations aren't generated yet
          setCurrentPage(location.start.displayed.page || 1);
          setTotalPages(location.start.displayed.total || 100);
          setReadingProgress((location.start.percentage || 0) * 100);
        }
      }
    });

    const keyListener = (event) => {
      if (event.key === 'ArrowLeft') {
        prev();
      }
      if (event.key === 'ArrowRight') {
        next();
      }
    };

    document.addEventListener('keyup', keyListener);
    window.addEventListener('resize', adjustReaderHeight);

    return () => {
      document.removeEventListener('keyup', keyListener);
      window.removeEventListener('resize', adjustReaderHeight);
      if (renditionRef.current) renditionRef.current.destroy();
      if (bookRef.current) bookRef.current.destroy();
    };
  }, [epubUrl, isDarkMode]);

  // Update font size in storage when it changes
  useEffect(() => {
    if (currentCfi) {
      saveReadingState(currentCfi);
    }
  }, [fontSize]);

  const applyTheme = (themeName, rendition) => {
    if (!rendition) return;

    if (themeName === 'dark') {
      rendition.themes.register('dark', {
        body: {
          color: ' #e8e8e8 !important',
          background: 'transparent !important',
        },
        'p, div, h1, h2, h3, h4, h5, h6, span, a': {
          color: 'rgb(255, 255, 255) !important',
        },
        'a:link': {
          color: ' #9ab8ff !important',
        },
      });
      rendition.themes.select('dark');
    } else {
      rendition.themes.register('light', {
        body: {
          color: '#2e2e2e !important',
          background: 'transparent !important',
        },
        'p, div, h1, h2, h3, h4, h5, h6, span': {
          color: '#2e2e2e !important',
        },
        'a:link': {
          color: '#0066cc !important',
        },
      });
      rendition.themes.select('light');
    }
  };

  const handleBack = () => {
    navigate(-1);
  }

  const prev = () => {
    if (renditionRef.current) {
      renditionRef.current.prev();
    }
  };

  const next = () => {
    if (renditionRef.current) {
      renditionRef.current.next();
    }
  };

  const increaseFontSize = () => {
    const newSize = fontSize + 10;
    setFontSize(newSize);
    if (renditionRef.current) {
      renditionRef.current.themes.fontSize(`${newSize}%`);
      // Use current CFI to maintain position with font size change
      if (currentCfi) {
        renditionRef.current.display(currentCfi);
      }
      adjustReaderHeight();
    }
  };

  const decreaseFontSize = () => {
    const newSize = Math.max(70, fontSize - 10);
    setFontSize(newSize);
    if (renditionRef.current) {
      renditionRef.current.themes.fontSize(`${newSize}%`);
      // Use current CFI to maintain position with font size change
      if (currentCfi) {
        renditionRef.current.display(currentCfi);
      }
      adjustReaderHeight();
    }
  };

  const downloadEpub = () => {
    const link = document.createElement('a');
    link.href = epubUrl;
    link.download = `${bookTitle}.epub` || 'book.epub';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const adjustReaderHeight = () => {
    if (readerContainerRef.current && renditionRef.current) {
      // Set a fixed height for the reader container
      const windowHeight = window.innerHeight;
      const headerHeight = 70; // Approximate height of your header
      const footerHeight = 70; // Approximate height of your footer
      const readerHeight = windowHeight - headerHeight - footerHeight - 40; // 40px buffer
      
      readerContainerRef.current.style.height = `${readerHeight}px`;
      
      // Re-render at current position
      if (currentCfi && renditionRef.current) {
        renditionRef.current.display(currentCfi);
      }
    }
  };

  const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 62,
    height: 34,
    padding: 7,
    '& .MuiSwitch-switchBase': {
      margin: 1,
      padding: 0,
      transform: 'translateX(6px)',
      '&.Mui-checked': {
        color: '#fff',
        transform: 'translateX(22px)',
        '& .MuiSwitch-thumb:before': {
          backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
            '#fff',
          )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
        },
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: '#aab4be',
          ...theme.applyStyles('dark', {
            backgroundColor: '#8796A5',
          }),
        },
      },
    },
    '& .MuiSwitch-thumb': {
      backgroundColor: '#001e3c',
      width: 32,
      height: 32,
      '&::before': {
        content: "''",
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          '#fff',
        )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
      },
      ...theme.applyStyles('dark', {
        backgroundColor: '#003892',
      }),
    },
    '& .MuiSwitch-track': {
      opacity: 1,
      backgroundColor: '#aab4be',
      borderRadius: 20 / 2,
      ...theme.applyStyles('dark', {
        backgroundColor: '#8796A5',
      }),
    },
  }));

  const StyledCircularIconButton = styled(IconButton)(({ theme }) => ({
    padding: '8px', // Consistent padding
    borderRadius: '50%', // Ensure it's circular
    backgroundColor: 'rgba(47, 146, 218, 0.76)',
    color: 'white',
    '&:hover': {
      backgroundColor: 'rgba(47, 146, 218, 0.9)',
    },
    width: '40px', // Fixed width
    height: '40px', // Fixed height (same as width)
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }));

  return (
    <Container maxWidth="md" sx={{ display: 'flex', flexDirection: 'column', borderRadius: '8px', color: isDarkMode ? '#e8e8e8' : '#2e2e2e', transition: 'color 0.3s ease', width: { xs: '100%', md: '80%' }, margin: '0 auto' }}>
      <Box className="reader-header" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'nowrap' }}>
        <Typography variant="h6" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: 'calc(100% - 80px)' }}>
          <BackButton handleBack={handleBack} /> {bookTitle || 'Loading...'}
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {/* <MaterialUISwitch
            checked={isDarkMode}
            onChange={toggleTheme}
            name="darkModeSwitch"
            className="darkToggle"
          /> */}<Typography sx={{color: isDarkMode?"white":'black' , padding: '8px 16px', borderRadius: '4px' }}>
                Download Epub
              </Typography>
          <StyledCircularIconButton onClick={downloadEpub} sx={{ ml: 1, color: 'black'}}>
            <AiOutlineDownload style={{ fontSize: '1rem' }} />
          </StyledCircularIconButton>
        </Box>
      </Box>

      {/* Progress Bar */}
      <Box sx={{ width: '100%', mb: 1 }}>
        <LinearProgress 
          variant="determinate" 
          value={readingProgress} 
          sx={{ 
            height: 4, 
            backgroundColor: isDarkMode ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)',
            '& .MuiLinearProgress-bar': {
              backgroundColor: 'rgb(47, 146, 218)'
            }
          }} 
        />
      </Box>

      <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        <Box ref={readerContainerRef} sx={{ 
          position: 'relative', 
          overflow: 'hidden', 
          width: '100%',
          height: '70vh', // Default height
          // border: '1px solid rgba(0,0,0,0.1)',
          borderRadius: '4px',
          // backgroundColor: isDarkMode ? '#333' : '#fff'
        }}>
          {isLoading ? (
            <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0, 0, 0, 0.7)', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', color: 'white', zIndex: 10 }}>
              <CircularProgress sx={{ marginBottom: '16px' }} />
              {/* <Typography>Loading your book...</Typography> */}
              <FullScreenLoading/>
            </Box>
          ) : !locationsGenerated ? (
            <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0, 0, 0, 0.2)', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', color: 'white', zIndex: 10, pointerEvents: 'none' }}>
              <Typography sx={{ backgroundColor: 'rgba(0, 0, 0, 0.7)', padding: '8px 16px', borderRadius: '4px' }}>
                Generating page information...
              </Typography>
            </Box>
          ) : null}
          <Box id="viewer" sx={{ 
            width: '100%', 
            height: '100%',
            overflowY: 'auto',
            // padding: '20px' 
          }} />
        </Box>
      </Box>

      <Box className="reader-footer" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <StyledCircularIconButton onClick={prev}>
            <AiOutlineLeft style={{ fontSize: '1rem' }} />
          </StyledCircularIconButton>
          <Typography sx={{ margin: '0 10px', fontSize: '0.85rem' }}>
            {locationsGenerated ? (
              <></>
            ) : (
              <FullScreenLoading/>
            )}
          </Typography>
          <StyledCircularIconButton onClick={next}>
            <AiOutlineRight style={{ fontSize: '1rem' }} />
          </StyledCircularIconButton>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <StyledCircularIconButton onClick={decreaseFontSize}>
            <AiOutlineZoomOut style={{ fontSize: '1rem' }} />
          </StyledCircularIconButton>
          <Typography sx={{ margin: '0 10px', fontSize: '0.85rem' }}>{fontSize}%</Typography>
          <StyledCircularIconButton onClick={increaseFontSize}>
            <AiOutlineZoomIn style={{ fontSize: '1rem' }} />
          </StyledCircularIconButton>
        </Box>
      </Box>
    </Container>
  );
};

export default KindleReader;