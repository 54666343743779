import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
// import PlaceIcon from '@mui/icons-material/Place';
import { API_BASE_URL, API_ENDPOINTS } from '../../../mainComponents/api.js';

const ScrollProgressIndicator = ({ bookId }) => {
  const [scrollProgress, setScrollProgress] = useState(0);
  const [bookmark, setBookmark] = useState(null);
  const containerRef = useRef(null);

  
  const fetchBookmark = async (username) => {
    try {
      const response = await fetch(`${API_BASE_URL}${API_ENDPOINTS.BOOKMARK}/${bookId}?username=${username}`);
      if (response.ok) {
        const data = await response.json();
        setBookmark(data.progress);
      } else {
        console.error('Error fetching bookmark. Response status:', response.status);
      }
    } catch (error) {
      console.error('Error fetching bookmark:', error);
    }
  };
  useEffect(() => {
    const username = localStorage.getItem('username');
    if (!username) return;

    fetchBookmark(username);
  }, [bookId]);

  const handleBookmarkClick = async () => {
    const username = localStorage.getItem('username');
    if (!username) return;

    try {
      const response = await fetch(`${API_BASE_URL}${API_ENDPOINTS.BOOKMARK}/${bookId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, progress: scrollProgress }),
      });
      if (response.ok) {
        setBookmark(scrollProgress);
      } else {
        const errorData = await response.json();
        console.error('Error setting bookmark. Response:', errorData);
      }
    } catch (error) {
      console.error('Error setting bookmark:', error);
    }
  };

  useEffect(() => {
    const updateScrollProgress = () => {
      const scrollPx = window.pageYOffset;
      const winHeightPx = document.documentElement.scrollHeight - document.documentElement.clientHeight;
      const scrolled = scrollPx / winHeightPx;
      setScrollProgress(scrolled);
    };

    window.addEventListener('scroll', updateScrollProgress);
    updateScrollProgress();
    return () => window.removeEventListener('scroll', updateScrollProgress);
  }, []);

  return (
    <Box
      ref={containerRef}
      sx={{
        position: 'fixed',
        right: '5px',
        top: '80px',
        bottom: '20px',
        width: '30px',
        backgroundColor: 'transparent',
        borderRadius: '4px',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <IconButton onClick={handleBookmarkClick} sx={{ mb: 1 , left:'18%'}}>
      <BookmarkAddIcon fontSize='large' sx={{ color: bookmark !== null ? 'rgb(0, 119, 255)' : 'rgba(173, 111, 41, 0.76)' }} />
      </IconButton>
      <Box
        sx={{
          height: 'calc(100% - 40px)',
          width: '4px',
          backgroundColor: 'rgba(0, 0, 0, 0)',
          borderRadius: '4px',
          position: 'relative',
          pointerEvents: 'none',
          zIndex: 1,
        }}
      >
        <Box
          sx={{
            height: `${scrollProgress * 100}%`,
            backgroundColor: 'rgba(47, 146, 218, 0.76)',
            borderRadius: '4px',
            transition: 'height 0.1s',
            position: 'relative',
            right: '-6px',
            pointerEvents: 'none',
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              bottom: 0,
              left: '50%',
              transform: 'translate(-50%, 50%)',
              width: '20px',
              height: '20px',
              backgroundColor: 'rgb(47, 147, 218)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '50%',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0)',
              pointerEvents: 'none',
              cursor: 'pointer'
            }}
            
          >
            <Typography
              variant="caption"
              sx={{
                color: 'white',
                fontWeight: 'bold',
                fontSize: '0.5rem',
              }}
            >
              {Math.round(scrollProgress * 100)}%
            </Typography>
          </Box>
        </Box>
        {bookmark !== null && (
          <Box
            sx={{
              position: 'absolute',
              left: '200%',
              top: `${bookmark * 100}%`,
              transform: 'translate(-50%, -50%)',
              width: '20px',
              height: '20px',
              backgroundColor: 'rgb(47, 147, 218)',
              borderRadius: '50%',
              zIndex:-1,
            }}
          />
          
        )}
      </Box>
    </Box>
  );
};

export default ScrollProgressIndicator;
