import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import QuoteCard from './components/quotecard';
import { API_BASE_URL, API_ENDPOINTS } from '../../mainComponents/api.js';
import { motion } from 'framer-motion';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { ThemeContext } from '../../mainComponents/theme.js';
import { useNavigate } from "react-router-dom";
import FullScreenLoading from '../../components/loadingScreen.js';
import BackButton from '../../components/backbutton.js';
import PageWrapper from "../../components/customWidth.js";
import { useMediaQuery } from '@mui/material';
import { Helmet } from 'react-helmet-async';

const useQuotes = (page, limit = 5) => {
    const [quotesData, setQuotesData] = useState({
        quotes: [],
        page: 1,
        total_pages: 1,
        total_quotes: 0
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchQuotes = async () => {
            setLoading(true);
            setError(null);
            try {
                const response = await fetch(`${API_BASE_URL}${API_ENDPOINTS.QUOTE}?page=${page}&limit=${limit}`);
                if (!response.ok) {
                    throw new Error('No more quotes available');
                }
                const data = await response.json();
                setQuotesData(data);
            } catch (error) {
                console.error('Error fetching quotes:', error);
                setError(error.message);
            }
            setLoading(false);
        };

        fetchQuotes();
    }, [page, limit]);

    return { quotesData, loading, error };
};

const QuotesPage = () => {
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);
    const limit = 5;
    const { quotesData, loading, error } = useQuotes(currentPage, limit);
    const isWideScreen = useMediaQuery('(min-width:1080px)');
    const customWidth = isWideScreen ? "80%" : "100%";
    const { isDarkMode } = React.useContext(ThemeContext);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const paginationStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '3rem',
        gap: '1rem',
    };

    const pageButtonStyle = {
        backgroundColor: isDarkMode ? 'rgba(47, 146, 218, 0.76)' : ' #f8f9fa',
        color: isDarkMode ? ' #ecf0f1' : ' #333',
        border: 'none',
        padding: '0.5rem 1rem',
        borderRadius: '50px',
        fontWeight: 'bold',
        transition: 'all 0.3s ease',
    };

    const arrowButtonStyle = {
        ...pageButtonStyle,
        display: 'flex',
        alignItems: 'center',
        gap: '0.5rem',
    };

    const handleBack = () => {
        navigate(`/`);
    }

    const backButtonStyle = {
        marginRight: '10px',
    };

    return (
        <PageWrapper customWidth={customWidth}>
            <Helmet>
                <title>ReadQuest - Quotes</title>
                <meta name="description" content="Read meaningful quotes from your favourite books." />
                <meta name="keywords" content="Readquest, read quest, book summaries, Sanket Magodia, read books, books, library, quotes, collection" />
            </Helmet>
            <Container className="my-5">
                <BackButton handleBack={handleBack} style={backButtonStyle}/>
                <h1 className="text-center mb-5 fw-bold text-primary">Inspiring Book Quotes</h1>
                
                {loading ? (
                    <FullScreenLoading/>
                ) : error ? (
                    <div className="text-center text-danger">{error}</div>
                ) : (
                    <>
                        <Row className="g-4">
                            {quotesData.quotes.map((quote, index) => (
                                <Col key={index} md={6} lg={4} className="mb-4">
                                    <motion.div
                                        initial={{ opacity: 0, y: 20 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        transition={{ duration: 0.5, delay: index * 0.1 }}
                                    >
                                        <QuoteCard
                                            quote={quote.quote}
                                            bookName={quote.book}
                                            explaination={quote.explaination}
                                        />
                                    </motion.div>
                                </Col>
                            ))}
                        </Row>
                        <div style={paginationStyle}>
                            <Button
                                style={arrowButtonStyle}
                                onClick={() => handlePageChange(currentPage - 1)}
                                disabled={currentPage === 1}
                            >
                                <FaArrowLeft /> Previous
                            </Button>
                            <div style={{
                                ...pageButtonStyle,
                                backgroundColor: 'rgba(47, 146, 218, 0.76)',
                                color: '#fff',
                            }}>
                                {currentPage} of {quotesData.total_pages}
                            </div>
                            <Button
                                style={arrowButtonStyle}
                                onClick={() => handlePageChange(currentPage + 1)}
                                disabled={currentPage === quotesData.total_pages}
                            >
                                Next <FaArrowRight />
                            </Button>
                        </div>
                    </>
                )}
            </Container>
        </PageWrapper>
    );
};

export default QuotesPage;
