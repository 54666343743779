import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Book as BookIcon } from '@mui/icons-material';
import { ThemeContext } from '../../../mainComponents/theme.js';

const FullBookCard = ({ post }) => {
  const navigate = useNavigate();
  const { isDarkMode } = React.useContext(ThemeContext);

  const handleFullBookClick = () => {
    const epubUrl = post.bookFile;
    navigate('/YourBook', { state: { epubUrl } });
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '200px',
        height: '300px',
        backgroundColor: isDarkMode? ' rgb(1, 8, 14)': 'rgb(255, 255, 255)', // Light grey background
        borderRadius: '8px',
        boxShadow: isDarkMode?'0 4px 8px rgba(211, 202, 202, 0.42)': '0 4px 8px rgba(0, 0, 0, 0.2)',
        padding: '16px',
        textAlign: 'center',
        position: 'relative',
        marginBottom: '16px',
      }}
    >
      {/* Book Icon or Image */}
      <Box
        sx={{
          width: '100%',
          height: '70%',
          backgroundColor: '#dfe6e9',
          borderRadius: '4px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'hidden',
          marginBottom: '12px',
        }}
      >
        {post.image_data ? (
          <img
            src={`data:image/jpeg;base64,${post.image_data}`}
            alt="Book Cover"
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          />
        ) : (
          <BookIcon sx={{ fontSize: 64, color: '#b2bec3' }} />
        )}
      </Box>

      {/* Book Title */}
      <Typography
        variant="h6"
        sx={{
          fontWeight: 'bold',
          fontSize: '16px',
          marginBottom: '8px',
          color:  isDarkMode? "#ffff":' #2d3436',
        }}
      >
        {post.title || "Full Book"}
      </Typography>

      {/* Author Name */}
      {post.author && (
        <Typography
          variant="body2"
          sx={{ marginBottom: '16px', color: isDarkMode? " #ffffff":' #2d3436', }}
        >
          by {post.author}
        </Typography>
      )}

      {/* Full Book Button */}
      <Button
        variant="contained"
        onClick={handleFullBookClick}
        sx={{
          backgroundColor: '#2f92da', // Primary color
          '&:hover': {
            backgroundColor: '#1e7bbd', // Darker shade on hover
            transform: 'scale(1.05)',
          },
          color: '#fff',
          fontWeight: 'bold',
          borderRadius: '4px',
          paddingX: 2,
          paddingY: 1,
          fontSize: 14,
          transition: 'all 0.3s ease-in-out',
        }}
      >
        Read Full Book
      </Button>
    </Box>
  );
};

export default FullBookCard;
