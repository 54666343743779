import React from 'react';
import { FaChevronDown, FaQuoteLeft, FaQuoteRight } from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion';
import { ThemeContext } from '../../../mainComponents/theme.js';

const QuoteCard = ({ quote, bookName, explaination }) => {
  const [expanded, setExpanded] = React.useState(false);
  const { isDarkMode } = React.useContext(ThemeContext);
  const theme = {
    backgroundColor: isDarkMode ? ' #011111fd' : ' #ffffff',
    textColor: isDarkMode ? ' #ecf0f1' : ' #333333',
    mutedColor: isDarkMode ? ' #bdc3c7' : ' #6c757d',
    accentColor: isDarkMode ? ' #3498db' : ' #007bff',
    hoverColor: isDarkMode ? ' #34495e' : ' #e9ecef',
  };

  const cardStyle = {
    borderRadius: '12px',
    boxShadow: '0 4px 6px rgba(47, 146, 218, 0.76)',
    overflow: 'hidden',
    transition: 'all 0.3s ease',
    border: 'none',
    backgroundColor: theme.backgroundColor,
  };

  const quoteStyle = {
    fontSize: '1.2rem',
    fontWeight: '500',
    color: theme.textColor,
    position: 'center',
    padding: '50px',
    lineHeight: '1.6',
  };

  const bookNameStyle = {
    textAlign: 'right',
    fontSize: '0.9rem',
    color: theme.mutedColor,
    fontStyle: 'italic',
    padding: '0 20px 10px',
  };

  const expandButtonStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    padding: '10px',
    backgroundColor: isDarkMode ? ' #011111fd' : ' #f8f9fa',
    border: 'none',
    cursor: 'pointer',
    // transition: 'background-color 0.3s ease',
  };

  const explanationStyle = {
    backgroundColor: isDarkMode ? ' #011111fd' : ' #f8f9fa',
    padding: '20px',
    fontSize: '1.2rem',
    color: theme.mutedColor,
    borderTop: `1px solid ${isDarkMode ? ' #4a6278' : ' #e9ecef'}`,
  };

  return (
    <div style={{ perspective: '1000px', height: '100%', padding: '20px' }}>
      <motion.div
        style={cardStyle}
        whileHover={{ 
          scale: 1.02, 
          boxShadow: isDarkMode
            ? '0 8px 12px rgba(255,255,255,0.15)'
            : '0 8px 12px rgba(0,0,0,0.15)' 
        }}
        transition={{ type: "spring", stiffness: 300 }}
      >
        <div style={quoteStyle}>
          <FaQuoteLeft 
            style={{ 
              position: 'absolute', 
              top: '10px', 
              left: '10px', 
              color: isDarkMode? "#ffff": 'rgba(47, 146, 218, 0.76)', 
            //   opacity: 0.5 
            }} 
          />
          {quote}
          <FaQuoteRight 
            style={{ 
              position: 'absolute', 
              bottom: '10px', 
              right: '10px', 
              color: isDarkMode? "#ffff": 'rgba(47, 146, 218, 0.76)', 
            //   opacity: 0.5
            }} 
          />
        </div>
        
        <div style={bookNameStyle}>
          — {bookName}
        </div>

        {explaination && (
          <div 
            style={expandButtonStyle}
            onClick={() => setExpanded(!expanded)}
            onMouseEnter={(e) => e.currentTarget.style.backgroundColor = 'rgba(47, 146, 218, 0.76)'}
            onMouseLeave={(e) => e.currentTarget.style.backgroundColor = isDarkMode ? '#011111fd' : '#f8f9fa'}
          >
            <FaChevronDown 
              style={{ 
                transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)', 
                transition: 'transform 0.3s ease',
                color: theme.mutedColor
              }} 
            />
          </div>
        )}

        <AnimatePresence>
          {expanded && explaination && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3 }}
              style={explanationStyle}
            >
              {explaination}
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
    </div>
  );
};

export default QuoteCard;
