import React, { useContext } from 'react';
import { Typography, Button, Box, Container, Grid, Card, CardContent, useMediaQuery, Paper, Chip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
// import { motion } from 'framer-motion';
import { ThemeContext } from '../../mainComponents/theme';
import MovingBookCovers from './components/carousal';
import BookIcon from '@mui/icons-material/Book';
// import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import WhatshotIcon from '@mui/icons-material/Whatshot';
// import bookImage from "../images/book.jpg";
import book1 from '../../images/alchemist.jpg';
import book2 from '../../images/anxiousppl.jpg';
import book3 from '../../images/the-power-of-discipline-cover.png';
import book4 from '../../images/think fast and slow.jpg';
import book5 from '../../images/psymoney.jpg';
import book6 from '../../images/wolfhall.jpg';
import book7 from '../../images/malice.jpg';
import book8 from '../../images/secondhandTime.jpg';
import book9 from '../../images/tuesdays with morrie.jpg';
import book10 from '../../images/NeverLetmeGo.jpg';
import book11 from '../../images/gilead.jpg';
import PageWrapper from "../../components/customWidth.js";
import { Helmet } from 'react-helmet-async';
// import { useMediaQuery } from '@mui/material';


const bookCovers = [book1, book2, book3, book4, book5,book6, book7, book8, book9, book10, book11];

const HomePage = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { isDarkMode } = useContext(ThemeContext);
  const isWideScreen = useMediaQuery('(min-width:1080px)');
  const customWidth = isWideScreen ? "80%" : "100%";
  return (
    <PageWrapper customWidth= {customWidth} >
      <Helmet>
                          <title>ReadQuest</title>
                          <meta name="description" content={`Elevate Your Mind, One Summary at a Time/`} />
                          <meta name="keywords" content={`Readquest, read quest, book summaries, Sanket Magodia, read books, books, library, quotes, quotes, collection`} />
                          {/* Add other meta tags as needed */}
                        </Helmet>
    <Box sx={{ 
      flexGrow: 1, 
      backgroundColor: 'transparent',
    }}>
      {/* Hero Section */}
      <Box
        sx={{
          // background: isDarkMode 
          //   ? 'linear-gradient(135deg,rgba(3, 46, 65, 0.43) 0%, #01080e 70%)'
          //   : 'linear-gradient(135deg, rgb(224, 236, 248) 0%, rgba(252, 252, 252, 0.99) 50%)',
          minHeight: '70vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
          overflow: 'hidden',
          paddingTop: 4,
          paddingBottom: 4,
          backgroundColor: 'transparent',
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={7} alignItems="center">
          <Grid item xs={12} md={6}>
              <Box sx={{ position: 'relative', height: '230px', width: '100%' }}>
                <MovingBookCovers bookCovers={bookCovers} />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                variant={isMobile ? "h4" : "h3"}
                component="h1"
                sx={{
                  marginTop: isMobile ? '0' : '80px',
                  fontWeight: 800,
                  fontFamily: 'Allura, cursive',
                  color: isDarkMode ? '#ffffff' : '#000000',
                  mb: 2,
                  textShadow: isDarkMode ? '2px 2px 4px rgba(0,0,0,0.5)' : 'none',
                  
                }}
              >
                Elevate Your Mind, One Summary at a Time
              </Typography>
              {/* <Typography
                variant="h6"
                sx={{
                  mb: 4,
                  fontFamily: '"Roboto", sans-serif',
                  color: isDarkMode ? 'rgba(255,255,255,0.8)' : 'rgba(0,0,0,0.7)',
                }}
              >
                Discover the essence of bestselling books in minutes. Fuel your personal growth with our expertly crafted summaries.
              </Typography> */}
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                <Button
                  variant="contained"
                  size="large"
                  startIcon={<BookIcon />}
                  onClick={() => navigate('/library')}
                  sx={{
                    backgroundColor: 'rgba(245, 0, 41, 0.64)',
                    '&:hover': { backgroundColor: '#c51162' },
                    fontSize: '1rem',
                    padding: '10px 20px',
                    borderRadius: '25px', 
                    fontFamily: '"Poppins", sans-serif',
                    fontWeight: 600,
                    boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
                  }}
                >
                  Explore Library
                </Button>
                
              </Box>
              <Box sx={{marginTop: '10px', display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                <Button
                  variant="contained"
                  size="large"
                  startIcon={<BookIcon />}
                  onClick={() => navigate('/quotes')}
                  sx={{
                    backgroundColor: 'rgba(22, 63, 176, 0.69)',
                    '&:hover': { backgroundColor: 'rgb(17, 20, 221)' },
                    fontSize: '1rem',
                    padding: '10px 20px',
                    borderRadius: '25px', 
                    fontFamily: '"Poppins", sans-serif',
                    fontWeight: 600,
                    boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
                  }}
                >
                  Read Quotes
                </Button>
                
              </Box>
            </Grid>
            
          </Grid>
        </Container>
      

      {/* What's New Section */}
      <Container maxWidth="lg" sx={{ my: 6 }}>
        <Paper elevation={3} sx={{ 
          p: 3, 
          borderRadius: '15px',
          backgroundColor: isDarkMode ? 'rgba(1, 8, 14, 0.93)' : 'rgba(255, 255, 255, 0.86)',
        }}>
          <Typography
            variant="h4"
            component="h2"
            sx={{
              mb: 3,
              fontWeight: 700,
              color: isDarkMode ? '#ffffff' : '#000000',
              
              fontFamily: '"Poppins", sans-serif',
            }}
          >
            <NewReleasesIcon sx={{ mr: 1, verticalAlign: 'middle' }} />
            What's New
          </Typography>
          <Grid container spacing={3}>
            {[
              { title: 'V6. LLM integration, Full eBooks', desc: 'Now ask questions while reading summaries to better grasp about books, Or read Full length eBooks' },
              { title: 'V5. Bookmarks, New UI', desc: 'Option to save and continue where you left' },
              { title: 'V4. Personalized Recommendations', desc: 'Content filtering book recommendations' },
              
            ].map((item, index) => (
              <Grid item xs={12} sm={4} key={index}>
                <Card sx={{ height: '100%', backgroundColor: isDarkMode ? 'rgba(6, 28, 46, 0.73)' : '#f5f5f5' , color: isDarkMode ? '#ffffff' : '#000000'}}>
                  <CardContent>
                    <Typography variant="h6" component="h3" gutterBottom sx={{ fontWeight: 600, fontFamily: '"Poppins", sans-serif' }}>
                      {item.title}
                    </Typography>
                    <Typography variant="body2" sx={{ fontFamily: '"Roboto", sans-serif' }}>
                      {item.desc}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
          <Box sx={{ mt: 3, textAlign: 'center' }}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => window.open('https://readquest.hashnode.space/default-guide/v6-llm-integration-full-ebooks-and-more-personalized-reading-experience', '_blank')}
              sx={{
                borderRadius: '20px',
                fontFamily: '"Poppins", sans-serif',
                fontWeight: 600,
              }}
            >
              View All Updates
            </Button>
          </Box>
        </Paper>
      </Container>
      </Box>
      

      {/* Popular Categories */}
      <Box sx={{ 
        bgcolor: isDarkMode ? '#01080e' : 'rgb(255, 255, 255)', 
        py: 6,
      }}>
        <Container maxWidth="lg">
          <Typography
            variant="h4"
            component="h2"
            align="center"
            sx={{
              mb: 4,
              fontWeight: 700,
              color: isDarkMode ? '#ffffff' : '#1a237e',
              fontFamily: '"Poppins", sans-serif',
            }}
          >
            <WhatshotIcon sx={{ mr: 1, verticalAlign: 'middle' }} />
            Popular Categories
          </Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: 2 }}>
            {['Crime/Mystery', 'Value Adding', 'Drama', 'History', 'Science Fiction', 'Adventure', 'Biography', 'Romance'].map((category) => (
              <Chip
                key={category}
                label={category}
                // onClick={() => navigate(`/category/${category.toLowerCase()}`)}
                sx={{
                  backgroundColor: isDarkMode ? 'rgba(7, 63, 103, 0.76)' : '#bbdefb',
                  color: isDarkMode ? '#ffffff' : '#1a237e',
                  '&:hover': { backgroundColor: isDarkMode ? 'rgb(29, 117, 205)' : '#90caf9' },
                  fontFamily: '"Roboto", sans-serif',
                  fontWeight: 500,
                  fontSize: '1rem',
                  padding: '20px 10px',
                }}
              />
            ))}
          </Box>
        </Container>
      </Box>

      
    </Box>
    </PageWrapper>
  );
};

export default HomePage;