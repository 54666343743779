import React, { useState, useRef, useEffect, useContext, useMemo } from "react";
import Groq from "groq-sdk";
import { ThemeContext } from '../../../mainComponents/theme.js';
import { marked } from 'marked';
import DOMPurify from 'dompurify';

const groq = new Groq({ 
  apiKey: "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",
  dangerouslyAllowBrowser: true
});

// Simple function to split text into chunks
const chunkText = (text, chunkSize = 500) => {
  const words = text.split(' ');
  const chunks = [];
  for (let i = 0; i < words.length; i += chunkSize) {
    chunks.push(words.slice(i, i + chunkSize).join(' '));
  }
  return chunks;
};

// Simple search function to find relevant chunks
const searchRelevantChunks = (chunks, query, numChunks = 2) => {
  return chunks
    .map((chunk, index) => ({ chunk, score: chunk.toLowerCase().split(query.toLowerCase()).length - 1, index }))
    .sort((a, b) => b.score - a.score)
    .slice(0, numChunks)
    .sort((a, b) => a.index - b.index)
    .map(item => item.chunk);
};

const ChatBot = ({ bookContent, bookTitle }) => {
  const { isDarkMode } = useContext(ThemeContext);
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState([
    { role: "assistant", content: `Hello! I'm your book assistant. I can answer anything about "${bookTitle}". How may I help you ?` }
  ]);
  const [input, setInput] = useState("");
  const [isHovered, setIsHovered] = useState(false);
  const messagesEndRef = useRef(null);

  // Memoize the chunked content to avoid re-chunking on every render
  const chunks = useMemo(() => chunkText(bookContent), [bookContent]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(scrollToBottom, [messages]);

  // Function to safely parse and render markdown
  const renderFormattedContent = (content) => {
    const rawMarkup = marked(content);
    const cleanHtml = DOMPurify.sanitize(rawMarkup);
    return { __html: cleanHtml };
  };

  const handleSend = async () => {
    if (!input.trim()) return;

    const userMessage = { role: "user", content: input };
    setMessages(prev => [...prev, userMessage]);

    try {
      // Find relevant chunks based on the user's input
      const relevantChunks = searchRelevantChunks(chunks, input);
      
      const response = await groq.chat.completions.create({
        messages: [
          {
            role: "system",
            content: `You are a book assistant. Answer questions very concisely in 1 sentence based on this context: ${relevantChunks.join(" ")}`
          },
          ...messages,
          userMessage
        ],
        model: "gemma2-9b-it",
      });

      const botMessage = {
        role: "assistant",
        content: response.choices[0]?.message?.content || "I couldn't find an answer."
      };
      
      setMessages(prev => [...prev, botMessage]);
    } catch (error) {
      setMessages(prev => [...prev, {
        role: "assistant",
        content: "Sorry, I'm having trouble connecting."
      }]);
    }

    setInput("");
  };

  const styles = {
    chatContainer: {
      position: 'fixed',
      bottom: '20px',
      right: '20px',
      zIndex: 1000,
    },
    chatBubble: {
      width: '50px',
      height: '50px',
      borderRadius: '50%',
      background: isHovered ? 'rgb(47, 147, 218)' : 'rgba(47, 147, 218, 0.58)',
      color: 'white',
      border: 'none',
      cursor: 'pointer',
      boxShadow: '0 2px 10px rgba(0,0,0,0.2)',
      fontSize: '24px',
      transition: 'background-color 0.3s ease',
    },
    chatWindow: {
      width: '350px',
      background: isDarkMode ? '#2c2c2c' : '#ffffff',
      borderRadius: '12px',
      boxShadow: isDarkMode? '0 5px 15px rgba(175, 162, 162, 0.49)':'0 5px 15px rgba(0,0,0,0.3)',
      overflow: 'hidden',
    },
    chatHeader: {
      padding: '16px',
      background: isDarkMode ? 'rgb(1, 8, 14)' : ' #f5f5f5',
      color: isDarkMode ? 'white' : '#333',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    closeButton: {
      background: 'none',
      border: 'none',
      color: isDarkMode ? 'white' : '#333',
      cursor: 'pointer',
      fontSize: '20px',
      position: 'absolute',
      right: '10px',
      top: '10px',
    },
    messagesContainer: {
      height: '400px',
      overflowY: 'auto',
      padding: '16px',
      background: isDarkMode ? 'rgb(1, 8, 14)' : '#fafafa',
    },
    messageBubble: (isUser) => ({
      display: 'inline-block',
      padding: '8px 12px',
      borderRadius: isUser ? '12px 12px 0 12px' : '12px 12px 12px 0',
      background: isUser 
        ? ( 'rgb(47, 147, 218)') 
        : (isDarkMode ? 'rgb(22, 34, 53)' : '#e0e0e0'),
      color: isDarkMode ? 'white' : (isUser ? 'white' : '#333'),
      maxWidth: '80%',
      boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
      marginBottom: '12px',
    }),
    inputArea: {
      padding: '16px',
      background: isDarkMode ? 'rgb(1, 8, 14)' : '#ffffff',
      borderTop: `1px solid ${isDarkMode ? ' #3a3a3a' : ' #e0e0e0'}`,
      display: 'flex',
      gap: '8px',
    },
    input: {
      flex: 1,
      padding: '16px',
      border: `1px solid ${isDarkMode ? 'rgb(131, 129, 129)' : ' #e0e0e0'}`,
      borderRadius: '20px',
      outline: 'none',
      background: isDarkMode ? 'rgb(1, 8, 14)': '#ffffff',
      color: isDarkMode ? 'white' : '#333',
    },
    sendButton: {
      padding: '8px',
      background: 'rgb(47, 147, 218)',
      color: 'white',
      border: 'none',
      borderRadius: '50%',
      cursor: 'pointer',
      width: '40px',
      height: '40px',
      fontSize: '18px',
    },
    formattedMessage: {
      '& p': { margin: '0 0 10px 0' },
      '& ul, & ol': { paddingLeft: '20px', margin: '0 0 10px 0' },
      '& code': {
        backgroundColor: isDarkMode ? '#2d2d2d' : '#f0f0f0',
        padding: '2px 4px',
        borderRadius: '4px',
        fontFamily: 'monospace',
      },
    },
  };

  return (
    <div style={styles.chatContainer}>
      {!isOpen ? (
        <button 
          style={styles.chatBubble} 
          onClick={() => setIsOpen(true)}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          💬
        </button>
      ) : (
        <div style={styles.chatWindow}>
          <div style={styles.chatHeader}>
            <h3 style={{ margin: 0 }}>Assistant</h3>
            <button style={styles.closeButton} onClick={() => setIsOpen(false)}>X</button>
          </div>

          <div style={styles.messagesContainer}>
            {messages.map((msg, index) => (
              <div key={index} style={{ textAlign: msg.role === 'user' ? 'right' : 'left' }}>
                <div 
                  style={{
                    ...styles.messageBubble(msg.role === 'user'),
                    ...(msg.role === 'assistant' ? styles.formattedMessage : {})
                  }}
                >
                  {msg.role === 'user' ? (
                    msg.content
                  ) : (
                    <div dangerouslySetInnerHTML={renderFormattedContent(msg.content)} />
                  )}
                </div>
              </div>
            ))}
            <div ref={messagesEndRef} />
          </div>

          <div style={styles.inputArea}>
            <input
              style={styles.input}
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && handleSend()}
              placeholder="Ask about the book..."
            />
            <button style={styles.sendButton} onClick={handleSend}>✉️</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatBot;
