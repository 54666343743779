import React, { useState, useContext } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { ThemeContext } from '../../mainComponents/theme.js';
import "../../App.css"
import LRheader from "./components/HeaderLR";
import { API_BASE_URL, API_ENDPOINTS } from '../../mainComponents/api.js'
import {
    Box,
    Typography,
    TextField,
    Button,
    Container,
    Link,
    Select,
    MenuItem,
    FormControl,
    InputLabel
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

export default function RegisterPage() {
    const [username, setUsername] = useState('');
    const [name, setName] = useState('');
    const [age, setAge] = useState('');
    const [email, setEmail] = useState('');
    const [sex, setSex] = useState('');
    const [password, setPassword] = useState('');
    const { isDarkMode } = useContext(ThemeContext);
    const navigate = useNavigate();

    async function register(ev) {
        ev.preventDefault();
        // Password validation
        if (!isPasswordValid(password)) {
            alert('Password must be at least 8 characters long and contain both letters and numbers.');
            return;
        }

        const response = await fetch(`${API_BASE_URL}${API_ENDPOINTS.REGISTER}`, {
            method: 'POST',
            body: JSON.stringify({ username, name, email, age, sex, password, read: [] }),
            headers: { 'Content-Type': 'application/json' }
        });

        if (response.status !== 200) {
            const errorData = await response.json();
            alert(`Registration failed: ${errorData.detail}`);
        } else {
            alert('Registration successful');
            navigate('/login');
        }
    }
    function isPasswordValid(password) {
        // Check if password is at least 8 characters long and contains both letters and numbers
        return password.length >= 8 && /[A-Za-z]/.test(password) && /[0-9]/.test(password);
    }

    if (localStorage.getItem('username')) {
        return <Navigate to="/" />;
    }

    return (
        <Box sx={{
            minHeight: '100vh',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: isDarkMode ? 'rgb(1, 8, 14)' : '#ffff',
        }}>
            <LRheader />

            <Container component="main" maxWidth="sm" sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
                <Box
                    sx={{
                        width: '100%',
                        padding: 4,
                        borderRadius: '20px',
                        backdropFilter: 'blur(10px)',
                        backgroundColor: isDarkMode ? 'rgba(255, 255, 255, 0.05)' : 'rgba(255, 255, 255, 0.8)',
                        boxShadow: isDarkMode ? '0 4px 30px rgba(0, 0, 0, 0.1)' : '0 4px 30px rgba(0, 0, 0, 0.1)',
                        border: isDarkMode ? '1px solid rgba(255, 255, 255, 0.1)' : '1px solid rgba(255, 255, 255, 0.3)',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <LockOutlinedIcon sx={{ fontSize: 40, mb: 2, color: 'rgba(47, 146, 218, 0.76)' }} />
                    <Typography component="h1" variant="h5" sx={{ mb: 3, color: isDarkMode ? '#fff' : 'rgb(4, 125, 141)' }}>
                        Register
                    </Typography>
                    <Box component="form" onSubmit={register} sx={{ mt: 1, width: '100%' }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="name"
                            label="Full Name"
                            name="name"
                            autoComplete="name"
                            autoFocus
                            value={name}
                            onChange={(ev) => setName(ev.target.value)}
                            sx={textFieldStyle(isDarkMode)}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            value={email}
                            onChange={(ev) => setEmail(ev.target.value)}
                            sx={textFieldStyle(isDarkMode)}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="age"
                            label="Age"
                            name="age"
                            type="number"
                            value={age}
                            onChange={(ev) => setAge(ev.target.value)}
                            sx={textFieldStyle(isDarkMode)}
                        />
                        <FormControl fullWidth margin="normal" sx={textFieldStyle(isDarkMode)}>
                            <InputLabel id="sex-label">Sex</InputLabel>
                            <Select
                                labelId="sex-label"
                                id="sex"
                                value={sex}
                                label="Sex"
                                onChange={(ev) => setSex(ev.target.value)}
                            >
                                <MenuItem value="Female">Female</MenuItem>
                                <MenuItem value="Male">Male</MenuItem>
                            </Select>
                        </FormControl>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="username"
                            label="Username"
                            name="username"
                            autoComplete="username"
                            value={username}
                            onChange={(ev) => setUsername(ev.target.value)}
                            sx={textFieldStyle(isDarkMode)}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="new-password"
                            value={password}
                            onChange={(ev) => setPassword(ev.target.value)}
                            sx={textFieldStyle(isDarkMode)}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{
                                mt: 3,
                                mb: 2,
                                backgroundColor: 'rgba(47, 146, 218, 0.76)',
                                '&:hover': {
                                    backgroundColor: '#115293',
                                },
                            }}
                        >
                            Register
                        </Button>
                        <Box sx={{ textAlign: 'center' }}>
                            <Link
                                component="button"
                                variant="body2"
                                onClick={() => navigate('/login')}
                                sx={{ color: 'rgba(47, 146, 218, 0.76)' }}
                            >
                                Already have an account? Sign In
                            </Link>
                        </Box>
                    </Box>
                </Box>
            </Container>
        </Box>
    );
}

const textFieldStyle = (isDarkMode) => ({
    '& .MuiInputBase-input': {
        color: isDarkMode ? '#fff' : 'inherit',
    },
    '& .MuiInputLabel-root': {
        color: isDarkMode ? '#bbb' : 'inherit',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0, 0, 0, 0.23)',
        },
    },
});