import React, { useEffect } from 'react';
import './App.css';
import Layout from './mainComponents/layout';
import IndexPage from './pages/indexPage/indexPage';
import LoginPage from './pages/loginRegister/loginPage';
import RegisterPage from './pages/loginRegister/registerPage';
import { Route, Routes } from "react-router-dom";
import CreatePost from './pages/createPost/createPost';
import FullPost from './pages/bookPage/bookPage';
import QuotesPage from './pages/quotesPage/quotespage';
import { ThemeProvider, ThemeContext } from './mainComponents/theme';
import HomePage from './pages/homepage/homepage';
import ScrollToTop from './components/scrolltop';
import AboutPage from './pages/about';
import { QueryClient, QueryClientProvider } from 'react-query';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { persistQueryClient } from '@tanstack/react-query-persist-client';
// import { API_BASE_URL, API_ENDPOINTS } from './mainComponents/api';
import { useGoogleAnalytics } from './mainComponents/useGoogleAnalytics';
import { HelmetProvider } from 'react-helmet-async';
// import KindleReader from './pages/bookPage/components/EpubReader';
import ReaderPage from './pages/bookPage/components/readerRender';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 1000 * 60 * 60, // Data is fresh for 1 hour
            cacheTime: 1000 * 60 * 60 * 24, // Cache retained for 24 hours
        },
    },
});

const persister = createSyncStoragePersister({
    storage: window.localStorage,
});

persistQueryClient({
    queryClient,
    persister,
});



function App() {
    useGoogleAnalytics();
    return (
        <QueryClientProvider client={queryClient}>
            <ThemeProvider>
                <AppContent />
            </ThemeProvider>
        </QueryClientProvider>
    );
}

function AppContent() {
    const { isDarkMode } = React.useContext(ThemeContext);
    // const queryClient = useQueryClient();

    useEffect(() => {
        if (isDarkMode) {
            document.body.classList.add('dark-mode');
        } else {
            document.body.classList.remove('dark-mode');
        }
    }, [isDarkMode]);

    // Prefetch all posts when the app loads
    

    return (
        <>

            <HelmetProvider>
                <ScrollToTop />
                <Routes>
                    <Route path={'/login'} element={<LoginPage />} />
                    <Route path={'/register'} element={<RegisterPage />} />
                    <Route path="/" element={<Layout />}>
                        <Route index element={<HomePage />} />
                        <Route path={'/library'} element={<IndexPage />} />
                        <Route path="/book/:bookId" element={<FullPost />} />
                        <Route path="/quotes" element={<QuotesPage />} />
                        <Route path="/about" element={<AboutPage />} />
                        <Route path="/YourBook" element={<ReaderPage />} />
                    </Route>
                    <Route path={'/create'} element={<CreatePost />} />
                </Routes>
            </HelmetProvider>
        </>
    );
}

export default App;
