import React, { useContext, useState, useEffect } from 'react';
import { IconButton, Typography, styled, Box, useMediaQuery, useTheme, Menu, MenuItem, Collapse } from "@mui/material";
import { ThemeContext } from '../../mainComponents/theme.js';
import Switch from '@mui/material/Switch';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { API_BASE_URL, API_ENDPOINTS } from '../../mainComponents/api'
import AnimatedLogo from './components/logo.js';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';

export default function Header() {
    const { isDarkMode, toggleTheme } = useContext(ThemeContext);
    const [booksRead, setBooksRead] = useState(0);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isLoggedIn = !!localStorage.getItem('username');
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();
    const [expanded, setExpanded] = useState(false);

    useEffect(() => {
        const fetchBooksRead = async () => {
            const username = localStorage.getItem('username');
            if (username) {
                try {
                    const response = await axios.get(`${API_BASE_URL}${API_ENDPOINTS.QUEST}?username=${username}`);
                    setBooksRead(response.data.books_read);
                } catch (error) {
                    console.error('Error fetching books read:', error);
                }
            }
        };

        fetchBooksRead();
    }, []);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleAuth = () => {
        if (isLoggedIn) {
            localStorage.removeItem('username');
            window.location.reload();
            navigate('/');
        } else {
            navigate('/login');
        }
    };

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const MaterialUISwitch = styled(Switch)(({ theme }) => ({
        width: 62,
        height: 34,
        padding: 7,
        '& .MuiSwitch-switchBase': {
            margin: 1,
            padding: 0,
            transform: 'translateX(6px)',
            '&.Mui-checked': {
                color: '#fff',
                transform: 'translateX(22px)',
                '& .MuiSwitch-thumb:before': {
                    backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
                        '#fff',
                    )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
                },
                '& + .MuiSwitch-track': {
                    opacity: 1,
                    backgroundColor: '#aab4be',
                    ...theme.applyStyles('dark', {
                        backgroundColor: '#8796A5',
                    }),
                },
            },
        },
        '& .MuiSwitch-thumb': {
            backgroundColor: '#001e3c',
            width: 32,
            height: 32,
            '&::before': {
                content: "''",
                position: 'absolute',
                width: '100%',
                height: '100%',
                left: 0,
                top: 0,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
                    '#fff',
                )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
            },
            ...theme.applyStyles('dark', {
                backgroundColor: '#003892',
            }),
        },
        '& .MuiSwitch-track': {
            opacity: 1,
            backgroundColor: '#aab4be',
            borderRadius: 20 / 2,
            ...theme.applyStyles('dark', {
                backgroundColor: '#8796A5',
            }),
        },
    }));

    return (
        <Box component="header" sx={{
            maxWidth: isMobile ? "100%" : "90%",
            margin: "0 auto",
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: isMobile ? 'center' : 'flex-start',
            padding: '10px 20px',
            color: isDarkMode ? '#fff' : '#000',
        }}>
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: isMobile ? 'space-between' : 'flex-start',
                width: isMobile ? '100%' : 'auto',
                marginBottom: isMobile ? '15px' : '0',
            }}>
                <AnimatedLogo isMobile={isMobile} />
                {isMobile && (
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        marginLeft: isMobile ? '0' : '15px',
                        marginTop: '6px',
                    }}>
                        <svg height="30px" width="30px" viewBox="0 0 512.004 512.004" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                            <g>
                                <circle fill="#0000FF" cx="251.703" cy="255.996" r="165.283" />
                                <circle fill="#FFA500" cx="253.215" cy="255.908" r="110.268" />
                            </g>
                            <circle fill="#42A555" cx="253.215" cy="77.506" r="68.135" />
                            <circle fill="#FFD700" cx="379.788" cy="129.347" r="68.506" />
                            <circle fill="#42A555" cx="431.63" cy="255.908" r="68.135" />
                            <circle fill="#FFD700" cx="126.679" cy="129.347" r="68.506" />
                            <circle fill="#42A555" cx="253.252" cy="434.498" r="68.135" />
                            <circle fill="#FFD700" cx="126.679" cy="382.656" r="68.506" />
                            <circle fill="#FFA500" cx="379.788" cy="382.656" r="68.506" />
                            <circle fill="#42A555" cx="74.849" cy="255.908" r="68.135" />
                            <circle fill="#6A0DAD" cx="252.653" cy="255.908" r="40.384" />
                            <circle fill="#FFFFFF" cx="252.653" cy="255.908" r="17.127" />
                        </svg>
                        <Typography
                            variant="h6"
                            sx={{
                                marginLeft: '5px',
                                fontWeight: 'bold',
                                color: 'inherit',
                            }}
                        >
                            {booksRead}
                        </Typography>
                    </Box>
                )}
            </Box>

            <Box sx={{
                display: 'flex',
                flexDirection: isMobile ? 'row' : 'row',
                alignItems: isMobile ? 'center' : 'center',
                justifyContent: isMobile ? 'center' : 'flex-end',
                marginTop: isMobile ? '-10px' : '0',
            }}>
                {!isMobile && (
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        marginRight: '20px',
                        marginTop: '30px',
                    }}>

                        <svg height="30px" width="30px" viewBox="0 0 512.004 512.004" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                            <g>
                                <circle fill="#0000FF" cx="251.703" cy="255.996" r="165.283" />
                                <circle fill="#FFA500" cx="253.215" cy="255.908" r="110.268" />
                            </g>
                            <circle fill="#42A555" cx="253.215" cy="77.506" r="68.135" />
                            <circle fill="#FFD700" cx="379.788" cy="129.347" r="68.506" />
                            <circle fill="#42A555" cx="431.63" cy="255.908" r="68.135" />
                            <circle fill="#FFD700" cx="126.679" cy="129.347" r="68.506" />
                            <circle fill="#42A555" cx="253.252" cy="434.498" r="68.135" />
                            <circle fill="#FFD700" cx="126.679" cy="382.656" r="68.506" />
                            <circle fill="#FFA500" cx="379.788" cy="382.656" r="68.506" />
                            <circle fill="#42A555" cx="74.849" cy="255.908" r="68.135" />
                            <circle fill="#6A0DAD" cx="252.653" cy="255.908" r="40.384" />
                            <circle fill="#FFFFFF" cx="252.653" cy="255.908" r="17.127" />
                        </svg>

                        <Typography
                            variant="h6"
                            sx={{
                                marginLeft: '5px',
                                fontWeight: 'bold',
                                color: 'inherit',
                            }}
                        >
                            {booksRead}
                        </Typography>
                    </Box>
                )}
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                }}>
                    {isMobile ? (
                        <>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center', // Center align the items
                                position: 'relative', // Add relative positioning
                            }}>
                                <IconButton
                                    onClick={handleExpandClick}
                                    sx={{
                                        width: '40px',
                                        height: '40px',
                                        borderRadius: '50%',
                                        marginLeft: '10px',
        
                                        backgroundColor: isDarkMode ? 'rgb(1, 8, 14)' : 'rgba(231, 227, 227, 0.45)',
                                        color: isDarkMode ? '#fff' : '#000',
                                        boxShadow: isDarkMode ? '#fff' : '0px 4px 10px rgba(0, 0, 0, 0.2)',
                                        transition: 'all 0.3s ease-in-out',
                                        '&:hover': {
                                            backgroundColor: isDarkMode ? 'rgba(178, 180, 182, 0.53)' : 'rgba(0,0,0,0.3)',
                                            transform: 'scale(1.1)',
                                        },
                                    }}
                                >
                                    <MenuIcon />
                                </IconButton>
                                <Collapse in={expanded} timeout="auto" unmountOnExit sx={{ position: 'absolute', top: '50px', width: '100%' }}>
                                <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    marginLeft: '10px',
                                    marginTop: '-5px',
                                }}
                            >
                                <MaterialUISwitch

                                    checked={isDarkMode}
                                    onChange={toggleTheme}
                                    name="darkModeSwitch"
                                    className="darkToggle"
                                />

                                <IconButton
                                    onClick={handleClick}
                                    sx={{
                                        width: '40px',
                                        height: '40px',
                                        borderRadius: '50%',
                                        backgroundColor: isDarkMode ? 'rgb(1, 8, 14)' : 'rgba(231, 227, 227, 0.45)',
                                        color: isDarkMode ? '#fff' : '#000',
                                        boxShadow: isDarkMode ? '#fff' : '0px 4px 10px rgba(0, 0, 0, 0.2)',
                                        transition: 'all 0.3s ease-in-out',
                                        '&:hover': {
                                            backgroundColor: isDarkMode ? 'rgba(178, 180, 182, 0.53)' : 'rgba(0,0,0,0.3)',
                                            transform: 'scale(1.1)',
                                        },
                                    }}
                                >
                                    <AccountCircleIcon fontSize="large" />
                                </IconButton>
                                <Menu
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    sx={{
                                        marginTop: 'px',
                                        '& .MuiPaper-root': {
                                            borderRadius: '12px',
                                            padding: '10px',
                                            backgroundColor: 'rgba(212, 210, 210, 0.9)',
                                            boxShadow: '0px 4px 20px rgba(235, 229, 229, 0.08)',
                                        },
                                    }}
                                >
                                    {isLoggedIn && (
                                        <MenuItem disabled>
                                            <Typography
                                                variant="h6"
                                                sx={{ fontWeight: 'bold', color: '#000' }}
                                            >
                                                {localStorage.getItem('username')}
                                            </Typography>
                                        </MenuItem>
                                    )}
                                    <MenuItem onClick={handleAuth} sx={{ textAlign: 'center', fontWeight: 'bold', color: '#000' }}>
                                        {isLoggedIn ? 'Sign Out' : 'Sign In'}
                                    </MenuItem>
                                </Menu>
                            </Box>
                                </Collapse>
                            </Box>
                        </>
                    ) : (
                        <>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                marginTop: '30px',
                                marginRight: '20px',
                                marginBottom: '0',
                            }}>
                                <MaterialUISwitch
                                    checked={isDarkMode}
                                    onChange={toggleTheme}
                                    name="darkModeSwitch"
                                    className="darkToggle"
                                />
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    marginTop: '30px',
                                }}
                            >
                                <IconButton
                                    onClick={handleClick}
                                    sx={{
                                        width: '40px',
                                        height: '40px',
                                        borderRadius: '50%',
                                        backgroundColor: isDarkMode ? 'rgb(1, 8, 14)' : 'rgba(231, 227, 227, 0.45)',
                                        color: isDarkMode ? '#fff' : '#000',
                                        boxShadow: isDarkMode ? '#fff' : '0px 4px 10px rgba(0, 0, 0, 0.2)',
                                        transition: 'all 0.3s ease-in-out',
                                        '&:hover': {
                                            backgroundColor: isDarkMode ? 'rgba(178, 180, 182, 0.53)' : 'rgba(0,0,0,0.3)',
                                            transform: 'scale(1.1)',
                                        },
                                    }}
                                >
                                    <AccountCircleIcon fontSize="large" />
                                </IconButton>
                                <Menu
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    sx={{
                                        marginTop: 'px',
                                        '& .MuiPaper-root': {
                                            borderRadius: '12px',
                                            padding: '10px',
                                            backgroundColor: 'rgba(212, 210, 210, 0.9)',
                                            boxShadow: '0px 4px 20px rgba(235, 229, 229, 0.08)',
                                        },
                                    }}
                                >
                                    {isLoggedIn && (
                                        <MenuItem disabled>
                                            <Typography
                                                variant="h6"
                                                sx={{ fontWeight: 'bold', color: '#000' }}
                                            >
                                                {localStorage.getItem('username')}
                                            </Typography>
                                        </MenuItem>
                                    )}
                                    <MenuItem onClick={handleAuth} sx={{ textAlign: 'center', fontWeight: 'bold', color: '#000' }}>
                                        {isLoggedIn ? 'Sign Out' : 'Sign In'}
                                    </MenuItem>
                                </Menu>
                            </Box>
                        </>
                    )}
                </Box>
            </Box>
        </Box>
    );
}
