import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import logoLite from '../../../images/trace.svg';   // SVG for light mode
import logoDark from '../../../images/trace1.svg';  // SVG for dark mode
import { ThemeContext } from '../../../mainComponents/theme';      // Import ThemeContext

// Logo Container with Padding and Responsiveness
const LogoContainer = styled(Link)(({ isMobile }) => ({
  textDecoration: 'none',
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  paddingTop: '20px', // Padding from the top
  marginBottom: isMobile ? '15px' : '0',
}));

// Optional: You can set width and height of the SVG if necessary
const StyledSVG = styled('img')(({ isMobile, isDarkMode }) => ({
  width: isMobile ? '200px' : '250px', 
  height: 'auto',
  filter: isDarkMode? 'drop-shadow(2px 2px 5px rgba(12, 12, 12, 0.81))':'drop-shadow(2px 2px 5px rgba(255, 255, 255, 0.73))' , // Adds a soft shadow
}));

const AnimatedLogo = ({ isMobile }) => {
  // Access the current theme from ThemeContext
  const { isDarkMode } = useContext(ThemeContext);

  // Choose the appropriate SVG based on the theme
  const logoSrc = isDarkMode ? logoDark : logoLite;

  return (
    <LogoContainer to="/" isMobile={isMobile}>
      <StyledSVG src={logoSrc} alt="ReadQuest Logo" isMobile={isMobile} isDarkMode = {isDarkMode} />
    </LogoContainer>
  );
};

export default AnimatedLogo;
