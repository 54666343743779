import React from 'react';
import { Card, CardActionArea, CardMedia, Typography, Box, useMediaQuery, useTheme, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import MenuBookIcon from '@mui/icons-material/MenuBook'; // Import the book icon

export default function Post({ post }) {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  const handleClick = () => {
    navigate(`/book/${post.slug}`, { state: { post } });
    console.log(post.id)
  };
  
  const width = isMobile ? 120 : 200;
  const height = isMobile ? 180 : 300;
  
  return (
    <Box sx={{ width: width, height: height, position: 'relative', overflow: 'hidden' }}>
      <Card 
        sx={{ 
          width: '100%', 
          height: '100%', 
          position: 'relative',
        }}
      >
        <CardActionArea 
          sx={{ 
            width: '100%', 
            height: '100%',
          }} 
          onClick={handleClick}
        >
          <CardMedia
            component="img"
            sx={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
            image={`data:image/jpeg;base64,${post.image_data}`}
            alt={post.title}
          />
          {/* Full Book Indicator */}
          {post.bookFile && (
            <Tooltip title="Full book available" arrow>
              <Box
                sx={{
                  position: 'absolute',
                  bottom: 8,
                  right: 8,
                  backgroundColor: 'rgba(47, 146, 218, 0.8)', // Brighter background
                  borderRadius: '4px',
                  padding: '4px 8px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: 'white', // White text
                  fontSize: '14px', // Slightly larger font
                  fontWeight: 'bold', // Bold text
                }}
              >
                <MenuBookIcon fontSize="small" sx={{ marginRight: 1 }} />
                Full eBook
              </Box>
            </Tooltip>
          )}
          <Box 
            sx={{ 
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.7)',
              color: 'white',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              opacity: 0,
              transition: 'opacity 0.3s',
              '&:hover': {
                opacity: 1,
              },
              padding: 2,
              overflow: 'auto',
            }}
          >
            <Typography gutterBottom variant="h6" component="div" align="center">
              {post.title}
            </Typography>
            <Typography variant="body2" align="center">
              {post.summary}
            </Typography>
          </Box>
        </CardActionArea>
      </Card>
    </Box>
  );
}
