import React, { useRef, useCallback } from "react";
import { useInfiniteQuery } from 'react-query';
import Post from "./components/post.js";
import SearchBar from "./components/searchbar.js"; // Import the new SearchBar component
import { API_BASE_URL, API_ENDPOINTS } from '../../mainComponents/api.js';
import FullScreenLoading from "../../components/loadingScreen.js";
import './components/IndexPage.css';
import PostSkeleton from "./components/skeletonLoader.js"; // Import your skeleton loader component
import {  useNavigate } from 'react-router-dom';
import { ThemeContext } from '../../mainComponents/theme.js';
import PageWrapper from "../../components/customWidth.js";
import { useMediaQuery, Typography } from '@mui/material';
import ContinueReading from "./components/ContinueReading.js";
import CPostSkeleton from "./components/CskeletonLoader.js";
import { Helmet } from 'react-helmet-async';
import FullBookCard from "./components/fullBookCard.js";
import libImg from "../../images/lib.svg"
// Function to fetch posts
const fetchPosts = async ({ pageParam = 1 }) => {
    const username = localStorage.getItem('username') || null;
    const response = await fetch(`${API_BASE_URL}${API_ENDPOINTS.FETCH}?page=${pageParam}&limit=7&username=${username}`);
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.json();
};

export default function IndexPage() {
    const scrollContainerRefs = useRef({});
    const navigate = useNavigate();
    const { isDarkMode } = React.useContext(ThemeContext);
    // Use react-query to fetch posts with infinite scrolling
    const isWideScreen = useMediaQuery('(min-width:1080px)');
    const customWidth = isWideScreen ? "70%" : "100%";
    const handleClick = (book) => {
        navigate(`/book/${book.slug}`, { state: { book } });
        console.log(book.id)
      };
    const {
        data,
        fetchNextPage,
        hasNextPage,
        isLoading,
        isFetchingNextPage,
    } = useInfiniteQuery('posts', fetchPosts, {
        getNextPageParam: (lastPage, pages) => {
            const hasMore = Object.values(lastPage).some(genre => 
                genre.total > (genre.page * genre.limit)
            );
            return hasMore ? pages.length + 1 : undefined;
        },
        staleTime: 1000 * 60 * 60, // Data considered fresh for 1 hour
        cacheTime: 1000 * 60 * 60 * 24, // Cache retained for 24 hours
    });

    const loadMoreForGenre = useCallback((genre) => {
        if (hasNextPage) {
            fetchNextPage();
        }
    }, [fetchNextPage, hasNextPage]);

    const handleScroll = useCallback((genre) => {
        const container = scrollContainerRefs.current[genre];
        if (container) {
            const { scrollLeft, scrollWidth, clientWidth } = container;
            if (scrollWidth - scrollLeft <= clientWidth + 10) { // Adding a buffer
                loadMoreForGenre(genre);
            }
        }
    }, [loadMoreForGenre]);

    const scroll = (categoryName, direction) => {
        const container = scrollContainerRefs.current[categoryName];
        if (container) {
            const scrollAmount = direction === 'left' ? -300 : 300;
            container.scrollBy({ left: scrollAmount, behavior: 'smooth' });
        }
    };

    if (isLoading) return <FullScreenLoading />;

    // Combine all pages of data
    const allGenres = (data?.pages || []).reduce((acc, page) => {
        Object.entries(page).forEach(([genre, genreData]) => {
            if (!acc[genre]) {
                acc[genre] = { ...genreData, books: [] };
            }
            acc[genre].books = [...acc[genre].books, ...(genreData.books || [])];
        });
        return acc;
    }, {});

    // Function to handle selection of a book title from suggestions
    const handleBookSelect = (title) => {
        console.log("Selected book:", title);
        navigate(`/book/${title}`);
    };
    if (localStorage.getItem('username')) {
        if (localStorage.getItem('username') === "admin") {
            navigate(`/create`);
        }
    } 
    
    return (

        <PageWrapper customWidth= {customWidth} >
            <Helmet>
                    <title>ReadQuest - Library</title>
                    <meta name="description" content="Go through wide collection of books and summaries and start reading today!" />
                    <meta name="keywords" content="Readquest, read quest, book summaries, Sanket Magodia, read books, books, library, collection" />
                    {/* Add other meta tags as needed */}
                  </Helmet>
        <div className="categories-container">
            <SearchBar onSelect={handleBookSelect} /> {/* Integrate SearchBar */}
           
           {/* Render continue first */}
           {allGenres["Continue Reading"] && (
                <div className="category recommendations" style={{
                    backgroundColor: ' rgba(7, 31, 56, 0)', // Light blue background
                    border: '2px  rgba(6, 76, 109, 0.3)', // Blue border
                    borderRadius: '10px', // Rounded corners
                    // padding: '20px', // Space inside the box
                    // marginBottom: '10px', // Space below recommendations
                    // boxShadow: '0 4px 15px rgba(13, 88, 110, 0.16)', // Subtle shadow effect
                    // marginTop: '30px', // Space above recommendations
                }}>
                    <h2  style={{
                        fontSize: '26px', // Larger font size for titles
                        color: isDarkMode? 'white': ' rgba(8, 50, 94, 0.82)', // Title color
                        textAlign: 'left', // Center align the title
                        fontWeight: 'bold', // Make the title bold
                    }}>Continue reading..</h2>
                    <div className="posts-scroll-wrapper">
                        <button className="scroll-button left" onClick={() => scroll("Continue Reading", 'left')}>
                            &#8249;
                        </button>
                        <div 
                            className="posts-scroll-container-continue"
                            ref={(el) => (scrollContainerRefs.current["Continue Reading"] = el)}
                            onScroll={() => handleScroll("Continue Reading")}
                        >
                            {allGenres["Continue Reading"].books.length > 0 ? (
                                allGenres["Continue Reading"].books.map((book) => (
                                    <ContinueReading key={book.slug} post={book} />
                                ))
                            ) : (
                                <p>No books available in this category.</p>
                            )}
                            {isFetchingNextPage && (
                                <>
                                    {/* Show skeleton loaders while fetching more posts */}
                                    <CPostSkeleton />
                                    <CPostSkeleton />
                                    <CPostSkeleton />
                                </>
                            )}
                        </div>
                        <button className="scroll-button right" onClick={() => scroll("Continue Reading", 'right')}>
                            &#8250;
                        </button>
                    </div>
                </div>
            )}
            
           {/* Separate section for full books with logo and vertical list */}
           {/* Separate section for full books with logo and vertical list */}
{allGenres["Full Books"] && (
  <div style={{
    display: 'flex',
    alignItems: 'stretch',
    padding: '20px',
    // border: '1px solid rgba(0, 0, 0, 0.1)',
    borderRadius: '10px',
    boxShadow: '0 4px 15px rgba(13, 88, 110, 0.16)',
    // backgroundColor: isDarkMode ? ' #1a1a1a' : '#f5f5f5',
  }}>
    {/* Book logo and text */}
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '30%',
      marginRight: '20px',
    }}>
      <img 
        src={libImg}
        alt="Full Books Logo" 
        style={{
          width: '200px',
          height: 'auto',
          marginBottom: '10px',
        }}
      />
      <h2 style={{
        fontSize: '24px',
        fontWeight: 'bold',
        color: isDarkMode ? '#ffffff' : '#333333',
        textAlign: 'center',
      }}>
        Full eBooks
      </h2>
    </div>
    
    {/* Scrollable list of book names */}
    <div style={{
      flex: '1',
      height: '300px', // Increased height
      overflowY: 'auto',
      padding: '10px',
    //   backgroundColor: isDarkMode ? ' #2a2a2a' : '#ffffff',
      borderRadius: '8px',
      boxShadow: 'inset 0 2px 4px rgba(0,0,0,0.1)',
    }}>
      {allGenres["Full Books"] && allGenres["Full Books"].books.map((book) => (
        <div 
          key={book.slug} 
          onClick={() => handleClick(book)}
          style={{
            padding: '10px',
            backgroundColor: isDarkMode ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.02)',
            borderRadius: '8px',
            cursor: 'pointer',
            marginBottom: '10px',
            transition: 'all 0.3s ease',
            boxShadow: '0 1px 3px rgba(0,0,0,0.12)',
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.backgroundColor = isDarkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.05)';
            e.currentTarget.style.transform = 'translateY(-2px)';
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.backgroundColor = isDarkMode ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.02)';
            e.currentTarget.style.transform = 'translateY(0)';
          }}
        >
          <span style={{
            fontSize: '16px',
            fontWeight: '500',
            color: isDarkMode ? '#e0e0e0' : '#333333',
            display: 'block',
          }}>
            {book.title}
          </span>
          <span style={{
            fontSize: '14px',
            color: isDarkMode ? '#b0b0b0' : '#666666',
            display: 'block',
            marginTop: '4px',
          }}>
            by {book.author}
          </span>
        </div>
      ))}
    </div>
  </div>
)}




            {/* Render Recommendations first */}
            {allGenres["Recommendations"] && (
                <div className="category recommendations" style={{
                    
                    backgroundColor: ' rgba(7, 31, 56, 0)', // Light blue background
                    border: '2px  rgba(6, 76, 109, 0.3)', // Blue border
                    borderRadius: '10px', // Rounded corners
                    padding: '20px', // Space inside the box
                    marginBottom: '40px', // Space below recommendations
                    // boxShadow: '0 4px 15px rgba(13, 88, 110, 0.16)', // Subtle shadow effect
                    marginTop: '10px', // Space above recommendations
                }}>
                    <h2 className="genre-title" style={{
                        
                        fontSize: '26px', // Larger font size for titles
                        color: isDarkMode? 'white': ' rgba(8, 50, 94, 0.82)', // Title color
                        textAlign: 'center', // Center align the title
                        fontWeight: 'bold', // Make the title bold
                    }}>Recommendations </h2>
                    <div className="posts-scroll-wrapper">
                        <button className="scroll-button left" onClick={() => scroll("Recommendations", 'left')}>
                            &#8249;
                        </button>
                        <div 
                            className="posts-scroll-container"
                            ref={(el) => (scrollContainerRefs.current["Recommendations"] = el)}
                            onScroll={() => handleScroll("Recommendations")}
                        >
                            {allGenres["Recommendations"].books.length > 0 ? (
                                allGenres["Recommendations"].books.map((book) => (
                                    <Post key={book.slug} post={book} />
                                ))
                            ) : (
                                <p>No books available in this category.</p>
                            )}
                            {isFetchingNextPage && (
                                <>
                                    {/* Show skeleton loaders while fetching more posts */}
                                    <PostSkeleton />
                                    <PostSkeleton />
                                    <PostSkeleton />
                                </>
                            )}
                        </div>
                        <button className="scroll-button right" onClick={() => scroll("Recommendations", 'right')}>
                            &#8250;
                        </button>
                    </div>
                </div>
            )}
    
            {/* Render other genres */}
            {Object.entries(allGenres).filter(([genre]) => genre !== "Recommendations" && genre !== "Continue Reading" && genre !== "Full Books").map(([genre, genreData]) => (
                <div key={genre} className="category">
                    <h2 className="genre-title">{genre}</h2>
                    <div className="posts-scroll-wrapper">
                        <button className="scroll-button left" onClick={() => scroll(genre, 'left')}>
                            &#8249;
                        </button>
                        <div 
                            className="posts-scroll-container"
                            ref={(el) => (scrollContainerRefs.current[genre] = el)}
                            onScroll={() => handleScroll(genre)}
                        >
                            {genreData.books.length > 0 ? (
                                genreData.books.map((book) => (
                                    <Post key={book.slug} post={book} />
                                ))
                            ) : (
                                <p>No books available in this category.</p>
                            )}
                            {isFetchingNextPage && (
                                <>
                                    {/* Show skeleton loaders while fetching more posts */}
                                    <PostSkeleton />
                                    <PostSkeleton />
                                    <PostSkeleton />
                                </>
                            )}
                        </div>
                        <button className="scroll-button right" onClick={() => scroll(genre, 'right')}>
                            &#8250;
                        </button>
                    </div>
                </div>
            ))}
        </div>
        </PageWrapper>
    );
}
