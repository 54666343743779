import React, { useState, useEffect, useRef } from 'react';
import { API_BASE_URL, API_ENDPOINTS } from '../../../mainComponents/api.js';
import { ThemeContext } from '../../../mainComponents/theme.js';
import BackButton from '../../../components/backbutton.js';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
const SearchBar = ({ onSelect }) => {
    const [searchTerm, setSearchTerm] = useState("");
    const [suggestions, setSuggestions] = useState([]);
    const { isDarkMode } = React.useContext(ThemeContext);
    const suggestionsRef = useRef(null); // Ref for suggestions dropdown
    const navigate = useNavigate();
    const slugify = (str) => {
        return str
            .toLowerCase() // Convert to lowercase
            .trim() // Trim leading and trailing whitespace
            .replace(/[^a-z0-9 -]/g, '') // Remove non-alphanumeric characters
            .replace(/\s+/g, '-') // Replace spaces with hyphens
            .replace(/-+/g, '-'); // Replace multiple hyphens with a single hyphen
    };
    useEffect(() => {
        if (searchTerm) {
            const fetchSuggestions = async () => {
                const response = await fetch(`${API_BASE_URL}${API_ENDPOINTS.SEARCH}?query=${encodeURIComponent(searchTerm)}`);
                const data = await response.json();
                setSuggestions(data); // Assuming data is an array of book titles
            };

            fetchSuggestions();
        } else {
            setSuggestions([]);
        }
    }, [searchTerm]);

    const handleChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleSelect = (title) => {
        setSearchTerm(title);
        setSuggestions([]);
        onSelect(slugify(title)); // Call the parent function to handle the selected title
    };

    // Close suggestions when clicking outside
    const handleClickOutside = (event) => {
        if (suggestionsRef.current && !suggestionsRef.current.contains(event.target)) {
            setSuggestions([]); // Close suggestions
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // Inline styles
    const searchContainerStyle = {
        display: 'flex',
        alignItems: 'center',
        position: 'relative', // Make this relative to position suggestions correctly
        width: '100%',
    };

    const inputStyle = {
        flex: 1, // Allow input to take remaining space
        padding: '10px',
        border: '1px solid #ccc',
        borderRadius: '4px',
        fontSize: '16px',
        backgroundColor: isDarkMode ? 'hsla(180, 89%, 4%, 0.992)' : 'white',
        color: isDarkMode ? 'white' : 'black',
        marginLeft: '10px', // Add some space between the button and input field
    };

    const suggestionsStyle = {
        position: 'absolute',
        top: '100%', // Position directly below the input
        left: 0,
        right: 0,
        backgroundColor: isDarkMode ? 'hsla(180, 89%, 4%, 0.992)' : 'white',
        border: '1px solid #ccc',
        borderRadius: '4px',
        maxHeight: '200px',
        overflowY: 'auto',
        zIndex: 1000,
    };

    const suggestionItemStyle = {
        padding: '10px',
        cursor: 'pointer',
        backgroundColor: isDarkMode ? 'hsla(180, 89%, 4%, 0.992)' : 'white',
    };

    return (
        <div style={searchContainerStyle}>
            <Box sx={{marginTop: '-7px'}}>

            <BackButton handleBack={() => navigate('/')} />
            </Box>
            <input 
                type="text" 
                placeholder="Search for books..." 
                value={searchTerm}
                onChange={handleChange}
                style={inputStyle}
            />
            {suggestions.length > 0 && (
                <ul style={suggestionsStyle} ref={suggestionsRef}>
                    {suggestions.map((title, index) => (
                        <li 
                            key={index} 
                            style={suggestionItemStyle} 
                            onMouseEnter={(e) => e.currentTarget.style.backgroundColor = isDarkMode ? '#000000' : '#f0f0f0'} // Hover effect
                            onMouseLeave={(e) => e.currentTarget.style.backgroundColor = isDarkMode ? 'hsla(180, 89%, 4%, 0.992)' : 'white'} // Reset background color
                            onClick={() => handleSelect(title)}
                        >
                            {title}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default SearchBar;
