import React from 'react';

const PageWrapper = ({ children, customWidth, scale }) => {
  const style = {
    width: customWidth || '100%',
    maxWidth: customWidth ? 'none' : '60%',
    margin: '0 auto',
    padding: '10px',
    transform: `scale(${scale})`,
    transformOrigin: 'top left',
  };

  return <main style={style}>{children}</main>;
};

export default PageWrapper;
