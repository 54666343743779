import React from 'react';
import { Box, Skeleton } from '@mui/material';

const CPostSkeleton = () => {
    return (
        <Box sx={{ 
            width: 100, 
            height: 100, 
            position: 'relative',
            margin: '0 10px',
            borderRadius: '50%',
            overflow: 'hidden',
        }}>
            {/* Main circular skeleton for the book cover */}
            <Skeleton 
                variant="circular" 
                width="100%" 
                height="100%" 
                sx={{ 
                    position: 'absolute',
                    top: 0,
                    left: 0,
                }} 
            />
            
            {/* Overlay skeleton for the title */}
            <Box 
                sx={{ 
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.7)',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '50%',
                }}
            >
                {/* Skeleton for the title */}
                <Skeleton 
                    variant="text" 
                    width="60%" 
                    height={12} 
                    sx={{ 
                        backgroundColor: 'rgba(255, 255, 255, 0.5)', 
                        borderRadius: '4px',
                    }} 
                />
            </Box>
        </Box>
    );
};

export default CPostSkeleton;