import React, { useState, useEffect, useRef } from 'react';
import { Button, Box, Slider } from '@mui/material';
import { PlayArrow as PlayArrowIcon, Pause as PauseIcon, Stop as StopIcon } from '@mui/icons-material';

const TextToSpeechComponent = ({ text, isDarkMode }) => {
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [progress, setProgress] = useState(0);
  const utteranceRef = useRef(null);
  const textRef = useRef(text);

  useEffect(() => {
    textRef.current = text;
  }, [text]);

  useEffect(() => {
    const synth = window.speechSynthesis;
    const utterance = new SpeechSynthesisUtterance(text);
    utteranceRef.current = utterance;

    utterance.onend = () => {
      setIsSpeaking(false);
      setProgress(0);
    };

    utterance.onboundary = (event) => {
      const { charIndex } = event;
      setProgress((charIndex / text.length) * 100);
    };

    return () => {
      synth.cancel();
    };
  }, [text]);

  const speak = (startIndex = 0) => {
    const synth = window.speechSynthesis;
    synth.cancel();

    const utterance = new SpeechSynthesisUtterance(textRef.current.slice(startIndex));
    utteranceRef.current = utterance;

    utterance.onend = () => {
      setIsSpeaking(false);
      setProgress(0);
    };

    utterance.onboundary = (event) => {
      const { charIndex } = event;
      setProgress(((startIndex + charIndex) / textRef.current.length) * 100);
    };

    synth.speak(utterance);
    setIsSpeaking(true);
    setIsPaused(false);
  };

  const pauseResume = () => {
    const synth = window.speechSynthesis;
    if (isPaused) {
      synth.resume();
    } else {
      synth.pause();
    }
    setIsPaused(!isPaused);
  };

  const stop = () => {
    const synth = window.speechSynthesis;
    synth.cancel();
    setIsSpeaking(false);
    setIsPaused(false);
    setProgress(0);
  };

  const seek = (value) => {
    const startIndex = Math.floor((value / 100) * textRef.current.length);
    speak(startIndex);
  };
  const primaryColor = 'rgba(47, 147, 218, 0.97)';
  const buttonStyle = {
    color: 'white',
    backgroundColor: primaryColor,
    '&:hover': {
      backgroundColor: 'rgb(47, 147, 218)',
    },
    border: `1px solid ${primaryColor}`,
  };

  return (
    <div style={{ padding: '10px', borderRadius: '8px', border: `1px solid ${primaryColor}` }}>
      <Button
        onClick={() => speak()}
        sx={{
          ...buttonStyle,
          mt: 2,
          width: '100%',
        }}
      >
        Read Aloud (Chrome/Safari on Desktop)
      </Button>

      {isSpeaking && (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 2 }}>
          <Slider
            value={progress}
            onChange={(_, newValue) => seek(newValue)}
            aria-labelledby="continuous-slider"
            sx={{
              width: '100%',
              mb: 2,
              color: primaryColor,
              '& .MuiSlider-thumb': {
                backgroundColor: primaryColor,
              },
              '& .MuiSlider-track': {
                backgroundColor: primaryColor,
              },
              '& .MuiSlider-rail': {
                backgroundColor: isDarkMode ? 'rgba(255,255,255,0.3)' : 'rgba(0,0,0,0.3)',
              },
            }}
          />
          <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
            <Button
              onClick={pauseResume}
              startIcon={isPaused ? <PlayArrowIcon /> : <PauseIcon />}
              sx={buttonStyle}
              variant="contained"
            >
              {isPaused ? 'Resume' : 'Pause'}
            </Button>
            <Button
              onClick={stop}
              startIcon={<StopIcon />}
              sx={buttonStyle}
              variant="contained"
            >
              Stop
            </Button>
          </Box>
        </Box>
      )}
    </div>
  );
};

export default TextToSpeechComponent;