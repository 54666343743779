import React, { useContext } from 'react';
import { Box, Container, Typography, Grid, Button, Link } from '@mui/material';
import { ThemeContext } from '../../mainComponents/theme';
import QuestionSubmission from './components/questions';
import { useNavigate } from 'react-router-dom';

const isLoggedIn = !!localStorage.getItem('username');

const Footer = () => {
    const { isDarkMode } = useContext(ThemeContext);
    const navigate = useNavigate();

    const scrollToAbout = () => {
        const aboutSection = document.getElementById('about-section');
        if (aboutSection) {
            aboutSection.scrollIntoView({ behavior: 'smooth' });
        }
        navigate('/about');
    };
    const scrollToHome = () => {
        
        navigate('/');
    };
    const scrollToLib= () => {
        
        navigate('/library');
    };
    const scrollToQuote= () => {
        
        navigate('/quotes');
    };
    const buttonStyle = {
        mt: 2,
        color: 'white',
        backgroundColor: 'rgb(47, 147, 218)', //'rgba(7, 63, 103, 0.76)',
        '&:hover': {
            backgroundColor: '#115293',
        },
        fontWeight: 'bold',
        width: '100%', // Make button full width
        
    };

    return (
        <Box
            component="footer"
            sx={{
                backgroundColor: isDarkMode ? 'rgba(1, 8, 14, 0.23)' : 'rgba(255, 255, 255, 0.15)',
                color: isDarkMode ? ' #fff' : ' #333',
                padding: '40px 0',
                width: '100%',
            }}
        >
            {/* <div className="curved-line"></div> */}
            <Container maxWidth="lg">
                <Grid container spacing={5}>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" sx={{ mb: 2, color: isDarkMode? 'rgba(47, 146, 218, 0.76)': 'rgba(47, 146, 218, 0.76)' }}>
                            Quick Links
                        </Typography>
                        <Link onClick = {scrollToHome} color="inherit" display="block" sx={{ mb: 1, cursor: 'pointer' }}>Home</Link>
                        <Link onClick = {scrollToLib} color="inherit" display="block" sx={{ mb: 1, cursor: 'pointer' }}>library</Link>
                        <Link onClick = {scrollToQuote} color="inherit" display="block" sx={{ mb: 1, cursor: 'pointer' }}>Quotes</Link>
                        
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" sx={{ mb: 2, color: isDarkMode? 'rgba(47, 146, 218, 0.76)': 'rgba(47, 146, 218, 0.76)' }}>
                            Connect With Us
                        </Typography>
                        {isLoggedIn ? (
                            <QuestionSubmission buttonStyle={buttonStyle} />
                        ) : (
                            <Typography>
                                For any inquiries, please contact us at:
                                <Link href="mailto:readthequest@gmail.com">
                                ReadTheQuest@gmail.com
                                </Link>
                            </Typography>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={4} container direction="column" justifyContent="space-between" >
                        <Typography variant="h6" sx={{ mb: 2, color: isDarkMode? 'rgba(47, 146, 218, 0.76)': 'rgba(47, 146, 218, 0.76)'}}>
                            About Us
                        </Typography>
                        <Typography variant="body2">
                            We are dedicated to providing quality content and fostering a community of lifelong learners.
                        </Typography>
                        <br/>
                        <Button
                            onClick={scrollToAbout}
                            sx={buttonStyle}
                        >
                            Learn More
                        </Button>
                    </Grid>
                    
                    
                </Grid>
            </Container>
        </Box>
    );
};

export default Footer;