import Header from "../pages/HeaderFooter/header"
import "../App.css"
import Footer from "../pages/HeaderFooter/footerPage"
import { Outlet } from "react-router-dom"
import { useLocation } from 'react-router-dom';
import { useMediaQuery, useTheme } from "@mui/material";

export default function Layout() {
    const location = useLocation();
    const isHomePage = location.pathname === "/";
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    
    return (
        <div className={`layout-container ${isHomePage ? 'homepage' : ''}`}>
            {/* {!isMobile && <div className="layout-image top-left"></div>}  For top-left image (all pages) */}
            {/* {!isMobile &&  <div className="layout-image bottom-right"></div>} For bottom-right image (all pages) */}
            {/* {!isMobile && isHomePage && <div className="layout-image top-right-home"></div>} */}
                <Header />
            <main>

                <Outlet />
                <div className="curved-line"></div>
                <Footer />
            </main>
        </div>
    )
}