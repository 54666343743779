// ReaderPage.js
import React from 'react';
import { useLocation } from 'react-router-dom';
import KindleReader from './EpubReader';
import { API_BASE_URL, API_ENDPOINTS } from '../../../mainComponents/api.js';
import { Helmet } from 'react-helmet-async';

const ReaderPage = () => {
  const location = useLocation();
  const epubUrl = location.state?.epubUrl;
  const fileName = `${API_BASE_URL}${API_ENDPOINTS.FILES}/${epubUrl.substring(epubUrl.lastIndexOf('/') + 1)}`;

  return (
    <div style={{ height: '100vh', width: '100%' }}>
      <Helmet>
        <title>ReadQuest - Ebook Reader</title>
        <meta name="description" content="Go through wide collection of books and summaries and start reading today!" />
        <meta name="keywords" content="Readquest, read quest, book summaries, Sanket Magodia, read books, books, library, collection" />
        {/* Add other meta tags as needed */}
      </Helmet>
      <KindleReader epubUrl={fileName} />
    </div>
  );
};

export default ReaderPage;
